<template>
  <div class="users">
    <div class="mainUsers">
      
              <!-- <Topbar /> -->
              <br>
              <div>
                 <h3>Users Page</h3>
                 <p>
                   Contains the users details when they sign in.
                 </p>

     
      </div>
      <br>


       <div class="details01">
         <div class="recentOrders">
              <div class="cardHeader">
                        <h2>Users</h2>
                        <a href="#" class="btnView" data-toggle="modal" data-target="#userAdd">Add New</a>
                        <!-- <span>{{users.length}}</span> -->
                    </div>
                <table>
                  <thead>
                    <tr>
                      <th>Name</th>
                      <th>Email</th>
                      <th>UserType</th>
                      <th>Modify</th>
                      <th>{{total}}</th>
                   </tr>
                  </thead>

                  <tbody>
                        <tr v-for="(user, key, index) in users" :key="user">
                      <td>{{user.name}}</td>
                      <td>{{user.email}}</td>
                      <td>{{user.userType}}</td>
                      <td style="display: none;">{{ key }}</td>
                      
                        <td>
                          <button class="btn btn-primary" data-toggle="modal" data-target="#userEdit" @click="editUser(user)">Edit</button>
                          <button class="btn btn-danger" @click="deleteUser(user.id)">Delete</button>
                        </td>
                        <td>{{ index }}</td>
                      </tr>
                  </tbody>
                  
                </table>
                <span style='display:none'>{{Created()}}</span>
            </div>
       </div>


        <div class="modal fade" id="userAdd" tabindex="-1" role="dialog" aria-labelledby="userTitle" aria-hidden="true">
          <div class="modal-dialog modal-dialog-centered" role="document">
            <div class="modal-content">
              <div class="modal-header">
                <h5 class="modal-title" id="exampleModalLongTitle">Add User</h5>
                <button type="button" class="close" data-dismiss="modal" aria-label="Close">
                  <span aria-hidden="true">&times;</span>
                </button>
              </div>
              <div class="modal-body">
                 <div class="form-group">
              <input type="text" placeholder="Name" v-model="user.name" class="form-control">
            </div>

            <div class="form-group">
              <input type="text" placeholder="Email" v-model="user.email" class="form-control">
            </div>

            <div class="form-group">
              <b-form-select v-model="user.userType" :options="options"></b-form-select>
            </div>

            <div class="form-group">
              <button @click="saveData" class="btn btn-primary">Add</button>
            </div>
              </div>
            </div>
          </div>
        </div>

                <!--Add user Modal -->
        <div class="modal fade" id="userEdit" tabindex="-1" role="dialog" aria-labelledby="userTitle" aria-hidden="true">
          <div class="modal-dialog modal-dialog-centered" role="document">
            <div class="modal-content">
              <div class="modal-header">
                <h5 class="modal-title" id="exampleModalLongTitle">Edit User</h5>
                <button type="button" class="close" data-dismiss="modal" aria-label="Close">
                  <span aria-hidden="true">&times;</span>
                </button>
              </div>
              <div class="modal-body">
                <div class="form-group">
                  <input type="text" placeholder="Name" v-model="user.name" class="form-control">
                </div>
                <div class="form-group">
                  <input type="text" placeholder="Email" v-model="user.email" class="form-control">
                </div>
                 <div class="form-group">
                  <b-form-select v-model="user.userType" :options="options"></b-form-select>
                </div>
              </div>
              <div class="modal-footer">
                <button type="button" class="btn btn-secondary" data-dismiss="modal">Close</button>
                <button type="button" class="btn btn-primary" @click="updateUser()">Save changes</button>
              </div>
            </div>
          </div>
        </div>
  </div>  
  </div>
</template>

<script>
import { db } from '../firebase.js';
// import Topbar from '@/components/Topbar.vue';
export default {
  name: "Users",
  components: {
    // Topbar
  },
  props: {
    msg: String
  },

  data(){
    return{
      users: '',
      user: {   
      name: null,
      email: null,
      userType: null,
      },
      activeItem: null,
      options: [
        { text: 'Admin' },
        { text: 'Customer' },
      ],
      total: 0,
    }
  },

    methods: {
      saveData(){
        db.collection("profiles").add(this.user)
        .then((docRef) => {
          console.log("Document written with ID: ", docRef.id);
          this.reset()  
          //this.readData();
        })
        .catch(function(error) {
          console.log("Error adding document: ", error);
        });
      },

      Created: async function(){
        if(Object.keys(this.users).length>0){return}  
        var urs = {}
        var c = ( await db.collection("profiles").get()).docs
        for(var i=0;i<c.length;i++){
          var doc = c[i]
          // this.total+=doc.data().index;
          // this.total=this.users.length
        urs[doc.id] = doc.data()
        urs[doc.id]['id'] = doc.id
        }
        this.users = urs
        this.See()
    },

       See: function(){
        console.log(this.users)
        //  db.collection('order-products').doc(id).delete()
    },

    updateUser(){
      db.collection("profiles").doc(this.activeItem).update(this.user)
      .then(function() {
        console.log("Document successfully updated!");
      })
      .catch(function (error) {
        console.error("Error updating document: ", error);
      })
    },

     editUser(user){
      this.user = user;
      this.activeItem = user.id;
    },

     deleteUser:function(id){
       db.collection("profiles").doc(id).delete().then(()=>{
         console.log(this.users)
        delete this.users[id]
         console.log(this.users)
       })
    }

    },

};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style>
.mainUsers{
    position: absolute;
    /* width: calc(100% - 300px); */
    left: 10px;
    min-height: 200vh;
    width: 100%;
    background: #fff;
    transition: 0.5s;
}
@media (max-width: 991px){
  .mainUsers{
    min-height: 650vh;
    overflow: hidden;
  }
}
</style>