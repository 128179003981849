<template> 
    <!-- sidebar-menu  -->
    <div class="admin">
        <div class="togg">
        <i class="fa fa-bars" aria-hidden="true" v-b-toggle.sidebar-1></i>
        </div>
    <br>
    <div class="main">
             
             <br>
              <div class="cardBox">
                <div class="card01">
                    <div class="nos">
                        <div class="numbers">0</div>
                        <div class="cardNumber">Customers</div>
                    </div>
                    <div class="iconBx">
                        <i class='bx bx-group' ></i>
                    </div>
                </div>

                <div class="card01">
                    <div class="nos">
                        <div class="numbers">0</div>
                        <div class="cardNumber">Orders</div>
                    </div>
                    <div class="iconBx">
                        <i class='bx bx-cart'></i>
                    </div>
                </div>

                <div class="card01">
                    <div class="nos">
                        <div class="numbers">0</div>
                        <div class="cardNumber">Messages</div>
                    </div>
                    <div class="iconBx">
                        <i class='bx bx-chat'></i>
                    </div>
                </div>

                <div class="card01">
                    <div class="nos">  
                        <div class="numbers">0</div>
                        <div class="cardNumber">Earnings</div>
                    </div>
                    <div class="iconBx">
                        <i class='bx bx-coin-stack'></i>
                    </div>
                </div>
                </div>

                <!-- charts -->
                <div class="graphBox">
                    <div class="box">
                        <p style="font-size: 20px; font-weight: 500;"><span style="color: #00c18a; font-size: 30px;">F</span>abric Sales</p>
                        <pie-chart :data="[['Blue', 44], ['Red', 23], ['Orange', 50], ['Green', 50], ['Purple', 50], ['White', 50], ['Pink', 50]]"></pie-chart>
                    </div>
                    <div class="box">
                        <p style="font-size: 20px; font-weight: 500;"><span style="color: #00c18a; font-size: 30px;">M</span>onthly Earnings</p>
                        <column-chart 
                        aria-label="first"
                        :data="[['Jan', 32], ['Feb', 46], ['March', 28], ['April', 34], ['May', 46], ['June', 22], ['July', 79],
                        ['Aug', 18], ['Sep', 55], ['Oct', 64], ['Nov', 46], ['Dec', 40]]"></column-chart>

                    </div>
                </div>

                <!--orders-->
                <div class="details">
                <div class="recentOrders">
                    <div class="cardHeader">
                        <h2>Recent Orders</h2>
                        <router-link to="/Admin/OrderDetails" class="btnView">View All</router-link>
                    </div>
                    <table>
                        <thead>
                            <tr>
                                <td>OrderId</td>
                                <td>User Email</td>
                                <td>Total</td>
                                <td>Payment</td>
                                <td>Status</td>
                            </tr>
                        </thead>
                        <tbody>
                            <tr v-for="detail in details" :key="detail"> 
                                <td>{{ detail.id }}</td>
                                <td>{{ detail.email }}</td>
                                <td>{{ detail.total }}</td>
                                <td>
                                  <div class="form-group">
                                    <b-form-select v-model="detail.status" :options="options"></b-form-select>
                                  </div>
                                </td>
                                <td>
                                  <div class="form-group">
                                      <select class="form-control" id="exampleFormControlSelect1">
                                      <option>Paid</option>
                                      <option>Due</option>
                                    </select>
                                  </div>
                                </td>
                            </tr>
                        </tbody>
                    </table>
                    <span style='display:none'>{{Created()}}</span>
                </div>

                <div class="recentCustomers">
                    <div class="cardHeader">
                        <h2>Recent Customers</h2>
                    </div>
                    <table>
                       
                        <t-body>
                            <tr v-for="user in users" :key="user">
                                    <td>{{user.name}}<br>
                                    {{user.email}}</td>
                            </tr>
                        </t-body>
                        
                    </table>
                    <span style='display:none'>{{Updated()}}</span>
                </div>
            </div>


        </div>

    <b-sidebar id="sidebar-1" title="ECOERIGREEN" shadow>
      <br>  
      <br>
          <ul>
                <li>
                    <router-link to="/Admin">
                        <span class="icon"><i class='bx bxs-home'></i></span>
                        <span class="title">Dashboard</span>
                    </router-link>
                </li>
                <li>
                    <router-link to="/Admin/Users">
                        <span class="icon"><i class='bx bxs-group'></i></span>
                        <span class="title">Customers</span>          
                    </router-link>
                </li>
                <li>
                    <router-link to="/Admin/Orders">
                        <span class="icon"><i class='bx bxs-cart' ></i></span>
                        <span class="title">Orders</span>     
                    </router-link>
                </li>
                <li>
                    <router-link to="/Admin/OrderDetails">
                        <span class="icon"><i class='bx bxs-cart-add'></i></span>
                        <span class="title">Order Details</span>     
                    </router-link>
                </li>
                <li>
                    <router-link to="/Admin/ProductsList">
                        <span class="icon"><i class='bx bxl-sketch'></i></span>
                        <span class="title">Products</span>
                    </router-link>
                </li>
                <li>
                    <router-link to="/Admin/Messages">
                        <span class="icon"><i class='bx bxs-chat'></i></span>
                        <span class="title">Messages</span>      
                    </router-link>
                </li>
                <li>
                    <a href="#" @click="logout()">
                        <span class="icon"><i class='bx bx-log-out-circle'></i></span>
                        <span class="title">Logout</span>
                    </a>
                </li>
            </ul>
    </b-sidebar>
        <!-- page-content  -->
        <main>
            <router-view/>
        </main>
        <!-- page-content" -->
    </div>
    
    
</template>

<script>
import firebase from '../firebase.js';
import { db } from '../firebase.js'

export default {
  name: "admin",
  data(){
      return{
            details:'',
            detail: {
            orderId: null,   
            email: null,
            price: null,
            payment: null,
            status: null,
            },
            activeItem: null,
            options: [
              { text: 'Delivered' },
              { text: 'Pending' },
              { text: 'In Progress' },
              { text: 'Return' }
          ],
            users: '',
            user: {   
            name: null,
            email: null,
        },
      
        //   name:null,
        //   email:null,
      }
  },

 methods:{
   logout(){
     firebase.auth().signOut()
     .then(() => {
              this.$router.replace('/');
          })
      .catch((err) =>{
              console.log(err);
          });    
   },
         saveData(){
        db.collection("orderDetails").add(this.detail)
        .then((docRef) => {
          console.log("Document written with ID: ", docRef.id);
          // this.reset()  
          //this.readData();
        })
        .catch(function(error) {
          console.log("Error adding document: ", error);
        });
      },

    Created: async function(){
        if(Object.keys(this.details).length>0){return}
        var deets = {}
        var d = ( await db.collection("cart").get()).docs
        for(var i=0;i<d.length;i++){
          var doc = d[i]
        deets[doc.id] = doc.data()
        deets[doc.id]['id'] = doc.id
        }
        this.details = deets
    },

     Updated: async function(){
        if(Object.keys(this.users).length>0){return}  
        var urs = {}
        var c = ( await db.collection("profiles").get()).docs
        for(var i=0;i<c.length;i++){
          var doc = c[i]
        urs[doc.id] = doc.data()
        urs[doc.id]['id'] = doc.id
        }
        this.users = urs
    },
 },
    created(){
    var user = firebase.auth().currentUser;
    db.collection('profiles').where("email",'==',user.email).get().then(guy=>{
    this.email = guy.docs[0].data().name

    })
    //this.name = user.displayName;
}

}
</script>

<style>
.togg{
    position: relative;
    width: 55px;
    height: 55px;
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 2.0em;
    background: #00c18a;
    color: #fff;
    border-radius: 10px;
    cursor: pointer;
}
#sidebar-1{
    background: #00c18a;
}
ul li{
    position: relative;
    list-style: none;
}
ul li a{
    position: relative;
    display: block;
    width: 100%;
    display: flex;
    text-decoration: none;
    color: #111;
}
ul li:hover a
{
    color: #00c18a;
    text-decoration: none;
}
ul li a:hover{
    text-decoration: none;
}
ul li a .icon{
    position: relative;
    display: block;
    min-width: 60px;
    height: 60px;
    line-height: 70px;
    text-align: center;
}
ul li a .icon i{
    font-size: 1.75em;
}
ul li .title{
    min-width: 60px;
    height: 60px;
    line-height: 50px;
}

  
</style>
