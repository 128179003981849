<template>
<div class="finished01">
      <div class="slideshow01">                                        
            <div class="slide01">
                    <img src="../assets/suitHolder03.jpg">
                </div>
            
                <div class="slide01">
                    <img src="../assets/shoppingBags01.jpg">
                </div>
            
                <div class="slide01">
                    <img src="../assets/net03.jpg">
                </div>

                <div class="slide01">
                    <img src="../assets/3dStitchedBag03.jpg">
                </div>
            
                <div class="slide01">
                    <img src="../assets/laundryBag02.jpg">
                </div>
               
                <div class="slide01">
                    <img src="../assets/3dStitchedBag.jpg">
                </div>

                <div class="slide01">
                    <img src="../assets/w-cutBagB03.jpg">
                </div>

                 <div class="slide01">
                    <img src="../assets/shoppingBagYellow.jpg">
                </div>

                <div class="slide01">
                    <img src="../assets/w-cutBag02.jpg">
                </div>

                <div class="slide01">
                    <img src="../assets/seedBag02.jpg">
                </div>

                <div class="slide01">
                    <img src="../assets/3dStitchedBag03.jpg">
                </div> 
                

            
        </div>
        </div>
</template>

<script>
export default {

}
</script>

<style>
.finished01{
    display: flex;
    justify-content: center;
    align-items: center;
}
.slideshow01{
    position: relative;
    width: 400px;
    height: 400px;
    transform-style: preserve-3d;
    perspective: 1000px;
}
.slideshow01 .slide01{
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    animation: animate 30s linear infinite;
    transform: rotateY(90deg);
}
.slideshow01 .slide01 img{
    position: absolute;
    top: 0;
    left: 0;
    object-fit: cover;
    transform: rotateY(180deg);
}
.slideshow01 .slide01:nth-child(1){
    animation-delay: 0s;
}
.slideshow01 .slide01:nth-child(2){
    animation-delay: 3s;
}
.slideshow01 .slide01:nth-child(3){
    animation-delay: 6s;
}
.slideshow01 .slide01:nth-child(4){
    animation-delay: 9s;
}
.slideshow01 .slide01:nth-child(5){
    animation-delay: 12s;
}
.slideshow01 .slide01:nth-child(6){
    animation-delay: 15s;
}
.slideshow01 .slide01:nth-child(7){
    animation-delay: 18s;
}
.slideshow01 .slide01:nth-child(8){
    animation-delay: 21s;
}
.slideshow01 .slide01:nth-child(9){
    animation-delay: 24s;
}
.slideshow01 .slide01:nth-child(10){
    animation-delay: 27s;
}
.slideshow01 .slide01:nth-child(11){
    animation-delay: 30s;
}
.slideshow01 .slide01:nth-child(1){
    animation-delay: 33s;
}
/*.slideshow .slide:nth-child(2){
    animation-delay: 34s;
}*/

@keyframes animate{
    0%{
        transform: rotateY(90deg);
    }
    10%, 100%{
        transform: rotateY(270deg);
    }
}
</style>