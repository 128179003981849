<template>
<div class="finished">
      <div class="slideshow">                                        
            
                <div class="slide">
                    <img src="../assets/shoppingbag02.jpg">
                    <!-- <span class="tooltiptext">Tooltip text</span> -->
                </div>
            
                <div class="slide">
                    <img src="../assets/net04.jpg">
                </div>

                <div class="slide">
                    <img src="../assets/suitHolder.jpg">
                </div>

                <div class="slide">
                    <img src="../assets/3dStitchedBag.jpg">
                </div>
               
                <div class="slide">
                    <img src="../assets/w-cutBag02.jpg">
                </div>

                 <div class="slide">
                    <img src="../assets/laundryBag.jpg">
                </div>
                
                <div class="slide">
                    <img src="../assets/d-cutBag.jpg">
                </div>

                 <div class="slide">
                    <img src="../assets/shoppingbag02.jpg">
                </div>

                <div class="slide">
                    <img src="../assets/w-cutBag.jpg">
                </div>

                <div class="slide">
                    <img src="../assets/suitHolder02.jpg">
                </div>   

                <div class="slide">
                    <img src="../assets/seedBag03.jpg">
                </div>

            
        </div>
        </div>
</template>

<script>
export default {

}
</script>

<style>
.finished{
    display: flex;
    justify-content: center;
    align-items: center;
}
.tooltiptext {
  visibility: hidden;
  width: 120px;
  background-color: black;
  color: #fff;
  text-align: center;
  border-radius: 6px;
  padding: 5px 0;
  
  /* Position the tooltip */
  position: absolute;
  z-index: 1;
  top: -5px;
  right: 105%;
}

.tooltip:hover .tooltiptext {
  visibility: visible;
}
.slideshow{
    position: relative;
    width: 400px;
    height: 400px;
    transform-style: preserve-3d;
    perspective: 1000px;
}
.slideshow .slide{
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    animation: animate 30s linear infinite;
    transform: rotateY(90deg);
}
.slideshow .slide img{
    position: absolute;
    top: 0;
    left: 0;
    object-fit: cover;
    transform: rotateY(180deg);
}
.slideshow .slide:nth-child(1){
    animation-delay: 0s;
}
.slideshow .slide:nth-child(2){
    animation-delay: 3s;
}
.slideshow .slide:nth-child(3){
    animation-delay: 6s;
}
.slideshow .slide:nth-child(4){
    animation-delay: 9s;
}
.slideshow .slide:nth-child(5){
    animation-delay: 12s;
}
.slideshow .slide:nth-child(6){
    animation-delay: 15s;
}
.slideshow .slide:nth-child(7){
    animation-delay: 18s;
}
.slideshow .slide:nth-child(8){
    animation-delay: 21s;
}
.slideshow .slide:nth-child(9){
    animation-delay: 24s;
}
.slideshow .slide:nth-child(10){
    animation-delay: 27s;
}
.slideshow .slide:nth-child(11){
    animation-delay: 30s;
}
.slideshow .slide:nth-child(1){
    animation-delay: 33s;
}
/*.slideshow .slide:nth-child(2){
    animation-delay: 34s;
}*/

@keyframes animate{
    0%{
        transform: rotateY(90deg);
    }
    10%, 100%{
        transform: rotateY(270deg);
    }
}
</style>