<template>
  <div class="messages">
      <div class="mainUsers">
              <!-- <Topbar /> -->
             <br>
              <div>
                 <h3>Messages Page</h3>
                 <p>
                   This is for feedback purposes, in the contact section.
                 </p>
              
     
      </div>
      <br>
          

            <div class="details01">
              <div class="recentOrders">
                <div class="cardHeader">
                        <h2>Messages and inquiries list</h2>
                    </div>
                <table>
                  <thead>
                    <tr>
                      <th>Name</th>
                      <th>Email</th>
                      <th>Text</th>
                      <th></th>
                   </tr> 
                  </thead>

                  <tbody>
                        <tr v-for="message in messages" :key="message">
                      <td>{{message.name}}</td>
                      <td>{{message.email}}</td>
                      <td>{{message.text}}</td>
                         <td>
                          <button class="btn btn-danger" @click="deleteMessage(message.id)">Delete</button>
                        </td>
                      </tr>
                  </tbody>
                </table>
                <span>{{messages.length}}</span>
                <span style='display:none'>{{Created()}}</span>
                

            </div>

           </div>
            </div>         
          </div>
    

</template>

<script>
import { db } from '../firebase.js'
// import Topbar from '@/components/Topbar.vue';
export default {
  name: "Messages",
  components: {
    // Topbar
  },
  props: {
    msg: String
  },
 data(){
      return{
        messages:'',
        message: {
        name: null,   
        email: null,
        text: null
        },
        }
  },
  methods: {
    Created: async function(){
        if(Object.keys(this.messages).length>0){return}
        var mess = {}
        var c = ( await db.collection("messages").get()).docs
        for(var i=0;i<c.length;i++){
          var doc = c[i]
        mess[doc.id] = doc.data()
        mess[doc.id]['id'] = doc.id
        }
        this.messages = mess
        this.See()
    },
    See: function(){
      console.log(this.messages)
    },
    deleteMessage:function(id){
       db.collection("messages").doc(id).delete().then(()=>{
        delete this.messages[id]
       })
       
    }
  }
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
.details .recentOrders{
    position: relative;
    display: grid;
    height: 600px;
    overflow: visible;
    background: #fff;
    padding: 20px;
    box-shadow: 0 7px 25px rgba(0,0,0,0.08);
    border-radius: 20px;
}
</style>