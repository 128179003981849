<template>
    <div>
        <b-navbar toggleable="lg" type="dark" id="shop_logo">
            <b-navbar-brand @click="goHome" href="#">
               <div class="logo"> ECOERI<span>.</span></div>
            </b-navbar-brand>

            <!-- <b-navbar-toggle target="nav-collapse"></b-navbar-toggle> -->

            
               <b-navbar-nav class="ml-auto">
                <b-nav-item @click="gotoCart" active> 
                    <i class="fa fa-shopping-cart" aria-hidden="true" data-toggle="tooltip" data-placement="bottom" title="Shopping Cart"></i>
                    <!-- <span class="cart_count">{{cart.length}}</span> -->
                </b-nav-item>   

                <!-- <b-collapse id="nav-collapse" is-nav> -->
                <b-nav-item active id="id01">
                    <p style="color: #fff; font-size: 17px;">Hi, {{email}}</p>
                </b-nav-item>   
                <b-nav-item active data-toggle="modal" data-target="#login-modal" class="id02">
                   <i class="fas fa-user" id="login-btn" style="font-size: 20px;"></i>
                </b-nav-item>
                <!-- </b-collapse> -->
              </b-navbar-nav> 
        </b-navbar>
        <Login />

    </div>

</template>

<script>
import Login from '@/components/Login.vue'
import firebase from '../firebase.js';
import { db,auth } from '../firebase.js'
export default {
    components: {
        Login
    },
    data() {
        return {
            cart: '',
            name: null,  
            email: null,
        }
    },

    created(){
        if (JSON.parse(localStorage.getItem('products'))) {
            this.cart = JSON.parse(localStorage.getItem('products'))
        }
        else {
            return
        }
         var user = firebase.auth().currentUser;
         db.collection('profiles').where("email",'==',user.email).get().then(guy=>{
            this.email = guy.docs[0].data().name

        })
    },

    methods: {
        gotoCart(){
            this.$router.push('/Cart')
        },
        goHome(){
           this.$router.push('/') 
        },
        onSubmit(e){
            e.preventDefault();
            if (this.form.user=='admin' && this.form.pass=='456789') {
                this.$refs['my-modal'].hide()
                this.$router.push('/admin')
                localStorage.setItem('admin', JSON.stringify('true'))
            }
            else {
                return false;
            }
        },
        LogOut:function(){
            auth.signOut()
        },
        
    }
}
</script>
<style>
#shop_logo{
    background: #007481;
}
.logo{
   color: #fff;
   font-weight: 700;
   font-size: 1.4em;
}
.logo span{
    color: #9ff21e;
}
.shop_header{
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 70px;
    padding: 10px 100px;
    z-index: 10000;
    background: #007481;
    align-items: center;
    transition: 0.5s;
}
.shop_header #icons{
    display: flex;
    
}
.cart_count{
    font-size: 15px;
    color: #fff;
    padding: 0 5px;
    margin-left: -2px;
    background: #9ff21e;
    height: 20px;
    border-radius: 50%;
    width: 20px;
}
.fa-shopping-cart{
    font-size: 20px;
}
.shop_header #login-btn{
    font-size: 17px;
    color: white;
    cursor: pointer;
    margin-right: 20px;
}
.shop_header #login-btn:hover{
    color: #9ff21e;
} 
.shop_header #cart{
    font-size: 17px;
    color: white;
    cursor: pointer;
}
.shop_header .cart{
    font-size: 17px;
    color: white;
    cursor: pointer;
}
.shop_header #cart:hover{
    color: #9ff21e;
} 
.shop_header #cart .sticky{
    color: #111;
}
.shop_header .cart .sticky{
    color: #111;
}
@media (max-width: 700px){
    #id01{
        display: none;
    }
    .id02{
        display: none;
    }
}
</style>
