<template>

    <v-main>
      <router-view/>
    </v-main>
  
</template>

<script>

export default {
data: () => ({
  //
}),
};
</script>

<style>

</style>