<template>
  <div class="overview">
      <div class="mainUsers">
              <!-- <Topbar /> -->
             <br>
              <div>
                 <h3>Products List Page</h3>
                 <p>
                   Consists of the products produced and their details.
                 </p>
              <br>
              <div class="details01">
                <div class="recentOrders">
                  <div class="cardHeader">
                        <h2>Order Details</h2>
                    </div>
                    <table>
                      <thead>
                            <tr>
                                <td>Id</td>
                                <td>Image</td>
                                <td>Name</td>
                                <td>Price</td>
                            </tr>
                        </thead>
                        <tbody>
                          <tr v-for="(item, i) in items" :key="i"> 
                                <td>{{ item.id }}</td>
                                <td><img :src="item.img" style="max-width: 5rem;"></td>
                                <td>{{ item.name }}</td>
                                <td>{{ item.price}}</td>
                          </tr>
                        </tbody>
                    </table>
                </div>
              </div>
  </div>  
  </div>     
  </div>
</template>

<script>
import { db } from '../firebase.js';
// import Topbar from '@/components/Topbar.vue'
export default {
  name: "ProductsList",
  components: {
    // Topbar
  },
  props: {
    msg: String
  },
    data(){
    return{
      fabrics: '',
      fabric: {   
      color: null,
      quantity: null,
      },
      activeItem: null,
      items: [
        {
                    id: 1,
                    name: 'White Fabric',
                    price: 475,
                    img: require('@/assets/fabric/roll1.png'),
                },
                {
                    id: 2,
                    name: 'Red Fabric',
                    price: 500,
                    img: require('@/assets/fabric/roll2.png'),
                },
                {
                    id: 3,
                    name: 'Purple Fabric',
                    price: 500,
                    img: require('@/assets/fabric/roll3.png'),
                },
                {
                    id: 4,
                    name: 'Orange Fabric',
                    price: 500,
                    img: require('@/assets/fabric/roll4.png'),
                },
                {
                    id: 5,
                    name: 'Blue Fabric',
                    price: 500,
                    img: require('@/assets/fabric/roll5.png'),
                },
                {
                    id: 6,
                    name: 'Yellow Fabric',
                    price: 500,
                    img: require('@/assets/fabric/roll6.png'),
                },
                {
                    id: 7,
                    name: 'Green Fabric',
                    price: 475,
                    img: require('@/assets/fabric/roll7.png'),
                },
                {
                    id: 8,
                    name: 'Pink Fabric',
                    price: 500,
                    img: require('@/assets/fabric/roll8.png'),
                },
      ]
    }
  },

  methods: {
      saveData(){
        db.collection("products").add(this.fabric)
        .then((docRef) => {
          console.log("Document written with ID: ", docRef.id);
          this.reset()  
          //this.readData();
        })
        .catch(function(error) {
          console.log("Error adding document: ", error);
        });
      },

      Created: async function(){
        if(Object.keys(this.fabrics).length>0){return}  
        var fabrs = {}
        var c = ( await db.collection("products").get()).docs
        for(var i=0;i<c.length;i++){
          var doc = c[i]
        fabrs[doc.id] = doc.data()
        fabrs[doc.id]['id'] = doc.id
        }
        this.fabrics = fabrs
        // console.log(this.products)
        // console.log(Object.keys(this.products))\
        this.See()
    },

       See: function(){
        console.log(this.fabrics)
        //  db.collection('order-products').doc(id).delete()
    },

    updateFabric(){
      db.collection("products").doc(this.activeItem).update(this.fabric)
      .then(function() {
        console.log("Document successfully updated!");
      })
      .catch(function (error) {
        console.error("Error updating document: ", error);
      })
    },

     editFabric(fabric){
      this.fabric = fabric;
      this.activeItem = fabric.id;
    },

     deleteFabric:function(id){
       db.collection("products").doc(id).delete().then(()=>{
        delete this.fabrics[id]
       })
    }

    },
 


};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped lang="scss">

</style>