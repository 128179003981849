<template>
  <div class="login">
        <!-- Modal -->
<div class="modal fade" id="login-modal" tabindex="-1" role="dialog" aria-labelledby="loginTitle" aria-hidden="true">
    <div class="modal-dialog modal-dialog-centered" role="document">
        <div class="modal-content">

        <div class="modal-body">

            <ul class="nav nav-fill nav-pills mb-3" id="pills-tab" role="tablist">
                <li class="nav-item">
                    <a class="nav-link active" id="pills-home-tab" data-toggle="pill" href="#pills-login" role="tab" aria-controls="pills-login" aria-selected="true">Login</a>
                </li>
                <li class="nav-item">
                    <a class="nav-link" id="pills-register-tab" data-toggle="pill" href="#pills-register" role="tab" aria-controls="pills-register" aria-selected="false">Signup</a>
                </li>
            </ul>

            <div class="tab-content" id="pills-tabContent">
            <div class="tab-pane fade show active" id="pills-login" role="tabpanel" aria-labelledby="pills-login-tab">

                <h5 class="text-center">Login Please</h5>

                <form id="login-form">
                <div class="form-group">
                    <label for="exampleInputEmail1">Email address</label>
                    <input type="email" v-model="email" class="form-control" id="exampleInputEmail1" aria-describedby="emailHelp" placeholder="Enter email" required>
                    <small class="form-text text-muted">Required</small>
                </div>
                <div class="form-group">
                    <label for="exampleInputPassword1">Password</label>
                    <input type="password" @keyup.enter="login" v-model="password" class="form-control" id="exampleInputPassword1" placeholder="Password" required>
                </div>

                <div class="form-buttons">
                    <div class="form-group">
                    <button class="btn btn-primary" type="button" @click="login">Login</button>
                </div>
                <!-- <div class="form-group">
                    <button class="btn btn-primary" @click="LogOut()">Logout</button>
                </div> -->
                </div>
                </form>

            </div>
            <div class="tab-pane fade" id="pills-register" role="tabpanel" aria-labelledby="pills-register-tab">

         <h5 class="text-center">Create New Account</h5>
            <form id="signup-form">    
                <div class="form-group">
                    <label for="name">Full name</label>
                    <input type="text" v-model="name" class="form-control" id="name" placeholder="Full name" required>
                </div>

                <div class="form-group">
                    <label for="name">Phone number</label>
                    <input type="text" v-model="phone" class="form-control" id="phone" placeholder="Phone number" required>
                </div>

                <div class="form-group">
                    <label for="email">Email address</label>
                    <input type="email"  v-model="email" class="form-control" id="email" aria-describedby="emailHelp" placeholder="Enter email" required>
                </div>
                <div class="form-group">
                    <label for="password">Password</label>
                    <input type="password" v-model="password" class="form-control" id="password" placeholder="Password" required>
                </div>

                <div class="form-group">
                    <button class="btn btn-primary" @click="register" type="button">Signup</button>
                </div>
            </form>
            </div>
            </div>


                </div>

            </div>
        </div>
        </div>

  </div>
</template>

<script>
import firebase from '../firebase.js';
import {db, auth} from '../firebase.js';
import Swal from 'sweetalert2';
export default {
  name: "Login",

  data(){
      return {
          name: null,
          email: null,
          phone: null,
          password: null,
          userType: null,
          user:''
      }
  },

  methods:{
      register(){
            firebase.auth().createUserWithEmailAndPassword(this.email, this.password)
               .then((user) => {
                   this.user = user
                //    eslint-disable-next-line no-undef
                //    $('#login').modal('hide');
                 db.collection("profiles").add({
                    name: this.name,
                    email: this.email,
                    phone: this.phone
                   })      
                              

                    .then(function() {
                        console.log("Document successfully written!");
                        // alert('Sign up successful')
                        Swal.fire({
                            position: 'top-end',
                            icon: 'success',
                            title: 'Sign up was successful',
                            showConfirmButton: false,
                            timer: 1500
                            })
                        document.querySelector("#signup-form").reset()
                    })
                    .catch(function(error) {
                        console.error("Error writing document: ", error);
                    });

                    //this.$router.replace('Admin');
                })
                .catch(function(error) {
                // Handle Errors here.
                var errorCode = error.code;
                var errorMessage = error.message;
                if (errorCode == 'auth/weak-password') {
                    alert('The password is too weak.');
                } else {
                    alert(errorMessage);
                }
                console.log(error);
            });
                // Add a new document with a generated id.
            db.collection("profiles").add({
                    name: this.name,
                    email: this.email,
                    phone: this.phone
            })
            .then((docRef) => {
                console.log("Document written with ID: ", docRef.id);
            })
            .catch((error) => {
                console.error("Error adding document: ", error);
            });
      },
      
      login(){
          firebase.auth().signInWithEmailAndPassword(this.email, this.password)
                .then(() => {
                        Swal.fire({
                            position: 'top-end',
                            icon: 'success',
                            title: 'Logged in!',
                            showConfirmButton: false,
                            timer: 1500
                            })
                        console.log("Document successfully written!");
                        // document.querySelector("#login-form").reset(); 

                        document.getElementsByClassName("modal-backdrop")[0].style.display='none';
                        
                        var user = firebase.auth().currentUser;
                        db.collection('profiles').where("email",'==',user.email).get().then(person=>{
                            this.email = person.docs[0].data().userType;

                            if ( this.email === 'Admin') {
                                this.$router.replace('Admin');
                            }
                            else {
                                this.$router.replace('/');
                            }

                        })
                        
                        
                })
                .catch(function(error) {
                    // Handle Errors here.
                    var errorCode = error.code;
                    var errorMessage = error.message;
                    if (errorCode === 'auth/wrong-password') {
                        alert('Wrong password.');
                    } else {
                        alert(errorMessage);
                    }
                    console.log(error);
            });

      },
       LogOut:function(){
            auth.signOut()
        },

  }
};
</script>

<style>
.form-buttons{
    display: flex;
    justify-content: space-between;
}
</style>

