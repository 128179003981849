<template>
<div class="Overview">
  
        <div class="main">
             <!-- <Topbar /> -->
             <br>
              <div class="cardBox">
                <div class="card01">
                    <div class="nos">
                        <div class="numbers">0</div>
                        <div class="cardNumber">Customers</div>
                    </div>
                    <div class="iconBx">
                        <i class='bx bx-group' ></i>
                    </div>
                </div>

                <div class="card01">
                    <div class="nos">
                        <div class="numbers">0</div>
                        <div class="cardNumber">Orders</div>
                    </div>
                    <div class="iconBx">
                        <i class='bx bx-cart'></i>
                    </div>
                </div>

                <div class="card01">
                    <div class="nos">
                        <div class="numbers">0</div>
                        <div class="cardNumber">Messages</div>
                    </div>
                    <div class="iconBx">
                        <i class='bx bx-chat'></i>
                    </div>
                </div>

                <div class="card01">
                    <div class="nos">  
                        <div class="numbers">0</div>
                        <div class="cardNumber">Earnings</div>
                    </div>
                    <div class="iconBx">
                        <i class='bx bx-coin-stack'></i>
                    </div>
                </div>
                </div>

                <!-- charts -->
                <div class="graphBox">
                    <div class="box">
                        <p style="font-size: 20px; font-weight: 500;"><span style="color: #00c18a; font-size: 30px;">F</span>abric Sales</p>
                        <pie-chart :data="[['Blue', 44], ['Red', 23], ['Orange', 50], ['Green', 50], ['Purple', 50], ['White', 50], ['Pink', 50]]"></pie-chart>
                        <!-- <line-chart :data="chartData"></line-chart> -->
                    </div>
                    <div class="box">
                        <p style="font-size: 20px; font-weight: 500;"><span style="color: #00c18a; font-size: 30px;">M</span>onthly Earnings</p>
                        <column-chart 
                        aria-label="first"
                        :data="[['Jan', 32], ['Feb', 46], ['March', 28], ['April', 34], ['May', 46], ['June', 22], ['July', 79],
                        ['Aug', 18], ['Sep', 55], ['Oct', 64], ['Nov', 46], ['Dec', 40]]"></column-chart>

                    </div>
                </div>

                <!--orders-->
                <div class="details">
                <div class="recentOrders">
                    <div class="cardHeader">
                        <h2>Recent Orders</h2>
                        <router-link to="/Admin/OrderDetails" class="btnView">View All</router-link>
                    </div>
                    <table>
                        <thead>
                            <tr>
                                <td>OrderId</td>
                                <td>User Email</td>
                                <td>Total</td>
                                <td>Payment</td>
                                <td>Status</td>
                            </tr>
                        </thead>
                        <tbody>
                            <tr v-for="detail in details" :key="detail"> 
                                <td>{{ detail.id }}</td>
                                <td>{{ detail.email }}</td>
                                <td>{{ detail.total }}</td>
                                <td>
                                  <div class="form-group">
                                    <b-form-select v-model="detail.status" :options="options"></b-form-select>
                                  </div>
                                </td>
                                <td>
                                  <div class="form-group">
                                    <!-- <label for="exampleFormControlSelect1">Example select</label> -->
                                    <select class="form-control" id="exampleFormControlSelect1">
                                      <option>Paid</option>
                                      <option>Due</option>
                                    </select>
                                  </div>
                                </td>
                            </tr>
                        </tbody>
                    </table>
                    <span style='display:none'>{{Created()}}</span>
                    <!-- <span style='display:none'>{{See()}}</span> -->
                </div>

                <div class="recentCustomers">
                    <div class="cardHeader">
                        <h2>Recent Customers</h2>
                    </div>
                    <table>
                       
                        <t-body>
                            <tr v-for="user in users" :key="user">
                                    <td>{{user.name}}<br>
                                    {{user.email}}</td>
                            </tr>
                        </t-body>
                        
                    </table>
                    <span style='display:none'>{{Updated()}}</span>
                </div>
            </div>


        </div>


      
</div>
</template>
<script>
// import Topbar from '@/components/Topbar.vue'
import { db } from '../firebase.js'
export default {
  name: 'Overview',
  components: {
    // Topbar
  },
  data() {
      return{
          details:'',
            detail: {
            orderId: null,   
            email: null,
            price: null,
            payment: null,
            status: null,
            },
            activeItem: null,
            options: [
              { text: 'Delivered' },
              { text: 'Pending' },
              { text: 'In Progress' },
              { text: 'Return' }
          ],
            users: '',
            user: {   
            name: null,
            email: null,
            },
      }
  },
   methods:{
      saveData(){
        db.collection("orderDetails").add(this.detail)
        .then((docRef) => {
          console.log("Document written with ID: ", docRef.id);
          // this.reset()  
          //this.readData();
        })
        .catch(function(error) {
          console.log("Error adding document: ", error);
        });
      },

    Created: async function(){
        if(Object.keys(this.details).length>0){return}
        var deets = {}
        var d = ( await db.collection("cart").get()).docs
        for(var i=0;i<d.length;i++){
          var doc = d[i]
        deets[doc.id] = doc.data()
        deets[doc.id]['id'] = doc.id
        }
        this.details = deets
    },

     Updated: async function(){
        if(Object.keys(this.users).length>0){return}  
        var urs = {}
        var c = ( await db.collection("profiles").get()).docs
        for(var i=0;i<c.length;i++){
          var doc = c[i]
        urs[doc.id] = doc.data()
        urs[doc.id]['id'] = doc.id
        }
        this.users = urs
    },
   
    
    },
}
</script>
<style>
.main{
    position: absolute;
    /* width: calc(100% - 300px); */
    left: 10px;
    min-height: 200vh;
    width: 100%;
    background: #fff;
    transition: 0.5s;
}
/* .main.active{
    width: calc(100% - 60px);
    left: 70px;
} */
.cardBox{
    position: relative;
    width: 100%;
    padding: 20px;
    display: grid;
    grid-template-columns: repeat(4,1fr);
    grid-gap: 30px;
}
.cardBox .card01{
    position: relative;
    background: #fff;
    padding: 30px;
    box-shadow: 0 7px 25px rgba(0,0,0,0.08);
    border-radius: 20px;
    display: flex;
    justify-content: space-between;
    cursor: pointer;
}
.cardBox .card01 .numbers{
    position: relative;
    font-weight: 500;
    font-size: 2.5em;
    color: #00c18a;
}
.cardBox .card01 .cardName{
    font-size: 2.5em;
    color: #222;
    margin-top: 5px;
}
.cardBox .card01 .iconBx{
    font-size: 2.8em;
    color: #222;
}
.cardBox .card01:hover{
    box-shadow: 0 14px 50px rgba(0,0,0,0.16);
    transition: 0.7s;
}
.graphBox{
    position: relative;
    width: 100%;
    min-height: 200px;
    padding: 20px;
    display: grid;
    grid-template-columns: 1fr 2fr;
    grid-gap: 30px;
}
.graphBox .box{
    position: relative;
    width: 100%;
    background: #fff;
    padding: 20px;
    border-radius: 20px;
    box-shadow: 0 7px 25px rgba(0,0,0,0.08);
}
.details{
    position: relative;
    width: 100%;
    padding: 20px;
    display: grid;
    grid-template-columns: 2fr 1fr;
    grid-gap: 30px;
}
.details .recentOrders{
    position: relative;
    display: grid;
    height: 500px;
    overflow: hidden;
    background: #fff;
    padding: 20px;
    box-shadow: 0 7px 25px rgba(0,0,0,0.08);
    border-radius: 20px;
}
.cardHeader{
    display: flex;
    justify-content: space-between;
    align-items: flex-start;
}
.cardHeader{
    font-weight: 500;
    color: #00c18a;
}
.cardHeader .btnView{
    position: relative;
    padding: 5px 10px;
    background: #00c18a;
    text-decoration: none;
    color: #fff;
    border-radius: 6px;
}
.details table thead td{
    /* width: 100%;
    border-collapse: collapse; */
    margin-top: 10px;
}
.details .recentOrders table tr{
    color: #111;
    border-bottom: 1px solid rgba(0,0,0,0.1);
}
.details .recentOrders table tr:last-child{
    border-bottom: none;
}
 table tr td{
    padding: 10px;
}
 table tbody tr:hover{
    background: #00c18a;
    color: #fff;
}
.details .recentCustomers{
    position: relative;
    display: grid;
    overflow: hidden;
    min-height: 500px;
    background: #fff;
    padding: 20px;
    box-shadow: 0 7px 25px rgba(0,0,0,0.08);
    border-radius: 20px;
}
@media (max-width: 991px){
.graphBox{
    grid-template-columns: 1fr;
    height: auto;
}  
.details .recentOrders{
    overflow-x: auto;
}  
.navigation{
    left: -300px;
}
 .navigation.active{
    width: 300px;
    left: 0;
}    
.main{
    width: 100%;
    left: 0;
}
   
.cardBox{
    grid-template-columns: repeat(2,1fr);
}    
}
@media (max-width: 768px){
    .details{
        grid-template-columns: repeat(1,1fr);
    }
    .recentOrders{
        overflow-x: auto;
    }
}
@media (max-width: 480px){
    .cardBox{
        grid-template-columns: repeat(1,1fr);
    }
}
</style>