<template>
    <div>
        <br>
        <div style="display: flex; justify-content: center;">
        <p class="line"><span style="color: #9ff21e; font-weight: 500; font-size: 2.0rem">O</span>ur products are Non-woven and are 100% Polypropylene.</p>
        </div>
        <b-container class="mt-4 cont_product">
            
                <div class="card_product" v-for="(fruit,index) in fruits" :key="index">
                    <b-card
                        class="mb-4"
                        id="card_product"
                    >
                    <div class="imgProduct">
                        <img :src="fruit.img">
                    </div>

                    <div class="imgContent">
                        <b-card-text>
                            <h6>{{fruit.name}}</h6>
                            <h6>Price: Kshs {{fruit.price}}.00</h6>
                                <!-- <input type="number" id="quantity" name="quantity" min="50" max="500"> -->
                                <!-- <ul>
                                <li><p>{{fruit.details}}</p></li>
                                <li><p>{{fruit.details2}}</p></li>
                                </ul> -->       
                            <label :for="`type-${fruit.type}`">Pick GSM (minimum GSM is 50):</label>
                        <b-col sm="9">
                            <b-form-input v-model="fruit.value" :id="`type-${fruit.type}`" :type="fruit.type" min="50" max="1000"></b-form-input>
                        </b-col>

                        <div class="mt-2" style="display: none">Value: {{ fruit.value }}</div>                                  
                        </b-card-text>
                        
                        <!-- <label for="gsm">Pick GSM (minimum GSM is 50):</label>
                        <b-col sm="9">
                            <b-form-input id="number" type="number" min="50" max="1000"></b-form-input>
                        </b-col> -->

                        <b-button
                            v-if="!fruit.cart"
                            :disabled="fruit.cart"
                            @click="fruit.cart=true,
                            addtocart(JSON.parse(JSON.stringify(fruit)))"
                            block
                            href="#"
                            variant="success"
                        >Add to Cart</b-button>

                        <b-button
                            v-if="fruit.cart"
                            :disabled="fruit.cart"
                            block
                            href="#"
                            variant="warning"
                        >
                        <b-icon icon="check" scale="2" variant="success"></b-icon>
                        Added to Cart
                        </b-button>
                        </div> 
                    </b-card>
                     </div>

               <!-- </div> -->
            

        </b-container>
    </div>
</template>

<script>
import { db } from '../firebase.js';
import Swal from 'sweetalert2';
export default {
    data() {
        return {
            fruits: [
                {
                    id: 1,
                    quantity: 1,
                    name: 'White Fabric',
                    price: 475,
                    value: '',
                    type: 'number',
                    details: '100% Polypropylene',
                    details2: 'Non-woven',
                    img: require('@/assets/fabric/roll1.png'),
                    cart: false
                },
                {
                    id: 2,
                    quantity: 1,
                    name: 'Red Fabric',
                    price: 500,
                    type: 'number',
                    value: '',
                    details: '100% Polypropylene',
                    details2: 'Non-woven',
                    img: require('@/assets/fabric/roll2.png'),
                    cart: false
                },
                {
                    id: 3,
                    quantity: 1,
                    name: 'Purple Fabric',
                    price: 500,
                    type: 'number',
                    value: '',
                    details: '100% Polypropylene',
                    details2: 'Non-woven',
                    img: require('@/assets/fabric/roll3.png'),
                    cart: false
                },
                {
                    id: 4,
                    quantity: 1,
                    name: 'Orange Fabric',
                    price: 500,
                    type: 'number',
                    value: '',
                    details: '100% Polypropylene',
                    details2: 'Non-woven',
                    img: require('@/assets/fabric/roll4.png'),
                    cart: false
                },
                {
                    id: 5,
                    quantity: 1,
                    name: 'Blue Fabric',
                    price: 500,
                    type: 'number',
                    value: '',
                    details: '100% Polypropylene',
                    details2: 'Non-woven',
                    img: require('@/assets/fabric/roll5.png'),
                    cart: false
                },
                {
                    id: 6,
                    quantity: 1,
                    name: 'Yellow Fabric',
                    price: 500,
                    type: 'number',
                    value: '',
                    details: '100% Polypropylene',
                    details2: 'Non-woven',
                    img: require('@/assets/fabric/roll6.png'),
                    cart: false
                },
                {
                    id: 7,
                    quantity: 1,
                    name: 'Green Fabric',
                    price: 475,
                    type: 'number',
                    value: '',
                    details: '100% Polypropylene, Non-woven',
                    details2: 'Non-woven',
                    img: require('@/assets/fabric/roll7.png'),
                    cart: false
                },
                {
                    id: 8,
                    quantity: 1,
                    name: 'Pink Fabric',
                    price: 500,
                    type: 'number',
                    value: '',
                    details: '100% Polypropylene',
                    details2: 'Non-woven',
                    img: require('@/assets/fabric/roll8.png'),
                    cart: false
                },

            ]
        }
    },
    methods: {
        addtocart(product){
            let cart= []
            if (JSON.parse(localStorage.getItem('products'))) {
                cart = JSON.parse(localStorage.getItem('products'))
            }
            else {
                cart = []
            }
            cart.push(product);
            localStorage.setItem('products', JSON.stringify(cart))
                const Toast = Swal.mixin({
                toast: true,
                position: 'top-end',
                showConfirmButton: false,
                timer: 3000,
                timerProgressBar: true,
                didOpen: (toast) => {
                    toast.addEventListener('mouseenter', Swal.stopTimer)
                    toast.addEventListener('mouseleave', Swal.resumeTimer)
                }
                })
                Toast.fire({
                icon: 'success',
                title: 'Product added succesfully, Click cart to see details',
                })
            // this.$router.go()
            },

        addProducts(){
            db.collection('products').add({  products:this.fruits})
                .then((docRef) => {      
                    console.log("Document written with ID: ", docRef.id);        
                })
            .catch(function(error) {
                    console.log("Error adding document: ", error);
                });    
        },
    },
    created(){
        let cart = []
        if (JSON.parse(localStorage.getItem('products'))) {
            cart = JSON.parse (localStorage.getItem('products'))
        }
        else { 
            cart = []
        }
        for (let index = 0; index < cart.length; index++) {
            for (let index2 = 0; index2 < this.fruits.length; index2++) {
                if (cart[index].id == this.fruits[index2].id) {
                    this.fruits[index2].cart = true
                }
            }
        }
    },
       
        
}
</script>
<style>
.line{
    font-size: 1.5rem;
    text-align: center;
    white-space: nowrap;
    overflow: hidden;
    width: 47ch;
    animation: typewriter 4s steps(47) 1s, 
    cursor 500ms steps(47) infinite;
}
@keyframes typewriter{
    0%{width: 0;}
    100%{width: 47ch;} 
}
@media (max-width: 700px){
    .line{
        font-size: 1.1rem;
        white-space: normal;
        animation: none;
    }
    .line span{
        font-size: 1.4rem;
    }
}
.cont_product{
    display: flex;
    flex-wrap: wrap;
    justify-content: space-around;
}
.imgProduct{
    position: relative;
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    padding-top: 20px;
    z-index: 1;
}
.imgProduct img{
    max-width: 100%;
    transition: 0.5s;
}
#card_product{
    position: relative;
    width: 320px;
    height: 350px;
    margin: 20px;
    border: 15px solid #fff;
    border-radius: 20px;
    box-shadow: 0 5px 35px rgba(0,0,0,0.08);
    font-size: 1.0rem;
    overflow: hidden;
}
#card_product:hover .imgProduct img{
    max-width: 40%;
}
</style>
