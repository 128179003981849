import firebase from 'firebase/compat/app';
import 'firebase/compat/auth';
import 'firebase/compat/firestore'
// Required for side-effects
require("firebase/firestore");

const firebaseConfig = {
    apiKey: "AIzaSyBZQxrp2XjMKf5BUxp0XfSeW-Zu4zlQqEA",
    authDomain: "eco-erigreen.firebaseapp.com",
    projectId: "eco-erigreen",
    storageBucket: "eco-erigreen.appspot.com",
    messagingSenderId: "326991702942",
    appId: "1:326991702942:web:1ddcf4a0193b95c1adc48f",
    measurementId: "G-5CGLMWETK0"
  };

  // Initialize Firebase
 firebase.initializeApp(firebaseConfig);

const db = firebase.firestore()
const auth = firebase.auth()
const currentUser = auth.currentUser
export default firebase;
export {
    db,
    auth,
    currentUser,

}
