<template>
  <div class="body">
   <header>
    <nav class="navbar">   
    <a href="#banner" class="logo">ECOERI<span>.</span></a>
    <div id="nav">
    <ul class="navigation">
        <li><a href="#about" class="abot" onclick="toggleMenu();">About</a></li>
        <li><a href="#partners" class="part" onclick="toggleMenu();">Partners</a></li>
        <li><a href="#testimonials" class="test" onclick="toggleMenu();">Testimonials</a></li>
        <li><a href="#contact" class="cont" onclick="toggleMenu();">Contact</a></li>
        <li><router-link to="/Shop" class="shop">Shop</router-link></li>
        <!-- <li><router-link to="/Test" class="shop">Test</router-link></li> -->
        <li><i class="fas fa-user" id="login-btn" @click="showAccount= !showAccount"></i></li>
    </ul> 

    <div id="menu"><i class='bx bx-menu' @click="showMenu= !showMenu"></i></div>
    </div>
    <div class="menu" v-if="showAccount">
        <div class="menu-title">
        <p>Welcome {{email}} </p>
        <i class='bx bx-x' id="close" @click="showAccount = !showAccount"></i>
        </div>
        <hr>
        <div class="box">    
        <li><a href="#" class="logOut" v-show="user" @click="LogOut()">LOG OUT</a></li> 
        <!-- <li><router-link to="/AccountDetails" v-show="user">My Account</router-link></li>  
        <li><router-link to="/MyOrders" v-show="user">My Orders</router-link></li>     -->
        <li><button><router-link to="/SignIn" v-show="!user" class="signIn">SIGN IN</router-link></button></li>
        <br>
        </div>
    </div>
     
    <div class="menu" v-if="showMenu">
        <div class="menu-title">
        <h5>Menu</h5>
        <i class='bx bx-x' id="close" @click="showMenu = !showMenu"></i>
        </div>
        <hr>
        <div class="box">
        <li><p style="font-weight: 500; color: #555;"><span style="color: #9ff21e">W</span>elcome {{email}} </p></li>
        <li><i class="fas fa-user" id="login-btn"> <router-link to="/SignIn"> Account</router-link></i></li>    
        <li><a href="#about" class="abot" onclick="toggleMenu();">About</a></li>
        <li><a href="#partners" class="part" onclick="toggleMenu();">Partners</a></li>
        <li><a href="#testimonials" class="test" onclick="toggleMenu();">Testimonials</a></li>
        <li><a href="#contact" class="cont" onclick="toggleMenu();">Contact</a></li>
        <li><router-link to="/Shop" class="shop">Shop</router-link></li>
        <li><a href="#" class="logOut" v-show="user" @click="LogOut()">LOG OUT</a></li> 
        </div>
    </div>
    
    </nav>
</header> 
<Login />

    <section class="banner" id="banner">
        <div class="content">
            <h2>Eco Friendly Products Manufacturer</h2>
            <p>Leading fabric Manufacturer in Eastern Africa and beyond</p>
            <a href="#products" class="btn01">Our Products</a>
        </div>
        <ul class="sci">
        <a href="https://www.facebook.com/ecoerigreen" class="ico2"><i class="fab fa-facebook"></i></a>
        <a href="https://wa.me/0707389974" class="ico2"><i class="fab fa-whatsapp"></i></a>
        <a href="#" class="ico2"><i class="fab fa-twitter"></i></a>
        <a href="#" class="ico2"><i class="fab fa-linkedin"></i></a>
        <a href="http://maps.google.com/?q=Kings Business Park, MV6G+FQ4, Nairobi" class="ico2"><i class="fas fa-map-marker-alt"></i></a>
        </ul>
        <div class="custom-shape-divider-bottom-1631127850">
            <svg data-name="Layer 1" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 1200 120" preserveAspectRatio="none">
                <path d="M0,0V46.29c47.79,22.2,103.59,32.17,158,28,70.36-5.37,136.33-33.31,206.8-37.5C438.64,32.43,512.34,53.67,583,72.05c69.27,18,138.3,24.88,209.4,13.08,36.15-6,69.85-17.84,104.45-29.34C989.49,25,1113-14.29,1200,52.47V0Z" opacity=".25" class="shape-fill"></path>
                <path d="M0,0V15.81C13,36.92,27.64,56.86,47.69,72.05,99.41,111.27,165,111,224.58,91.58c31.15-10.15,60.09-26.07,89.67-39.8,40.92-19,84.73-46,130.83-49.67,36.26-2.85,70.9,9.42,98.6,31.56,31.77,25.39,62.32,62,103.63,73,40.44,10.79,81.35-6.69,119.13-24.28s75.16-39,116.92-43.05c59.73-5.85,113.28,22.88,168.9,38.84,30.2,8.66,59,6.17,87.09-7.5,22.43-10.89,48-26.93,60.65-49.24V0Z" opacity=".5" class="shape-fill"></path>
                <path d="M0,0V5.63C149.93,59,314.09,71.32,475.83,42.57c43-7.64,84.23-20.12,127.61-26.46,59-8.63,112.48,12.24,165.56,35.4C827.93,77.22,886,95.24,951.2,90c86.53-7,172.46-45.71,248.8-84.81V0Z" class="shape-fill"></path>
            </svg>
        </div>
    </section>

    <!--Floating Button-->
    <!-- <div class="fab-container">
        <router-link to="/Admin">
        <div class="fab fab-icon-holder">
           <i class="fas fa-arrow-up"></i>
        </div>
        </router-link>
    </div> -->

    <section class="about" id="about">
        <div class="row">
            <div class="col50">
                <div class="imgBx"  data-aos="fade-down"  data-aos-delay="300" data-aos-duration="3000">
                    <img src="../assets/aboutBanner02.jpg">
                </div>
            </div>
           
            <div class="col50" id="col50" data-aos="fade-up"  data-aos-delay="300" data-aos-duration="3000">
                <h2 class="titleText"><span>A</span>bout Us</h2>
                <p>ECO-ERI GREEN COMPANY LIMITED is a renowned manufacturer of non-woven fabric. The company ventured in non-woven fabric  in January 2018 after the Kenyan government banned usage of plastic bags .The Company boasts of the state of art machinery which has facilitated fast and consistent growth of production. The company is a market leader in producing quality non-woven fabric, in East Africa.
                    <br><br>Through mastery of the extrusion process, various raw materials are converted into an eco-friendly high grade non-woven fabric that is used to manufacture various products such as non-woven bags. Notably during this COVID 19 the company has ensured consistent production of Fabric required for the manufacturing of masks, which has enabled the prevention of the spread of the pandemic.
                </p>
            </div>
        </div>
    </section>

    <section class="products" id="products">
        <div class="title">
            <h2 class="titleText">Our <span>P</span>roducts</h2>
            <p>-Non-woven Fabric Red, Yellow, Green, Blue, White-</p>
        </div>
        <div class="content">
            <div class="box" data-aos="fade-up" data-aos-delay="150" data-aos-duration="1500">
                <div class="imgBx">
                    <img src="../assets/product01.jpg">
                </div>
                <div class="text">
                    <h4>Non-woven Fabric</h4>
                    <p>Available in blue, red, green, white, yellow, pink and black.</p>
                    <router-link to="/Shop" class="btn01">Order Now</router-link>
                </div>
            </div>
            <div class="box" data-aos="fade-up" data-aos-delay="150" data-aos-duration="1500">
                <div class="imgBx">
                    <img src="../assets/rolls03.jpg">
                </div>
                <div class="text">
                    <h4>Non-woven Fabric</h4>
                    <p>Available in blue, red, green, white, yellow, pink and black.</p>
                    <router-link to="/Shop" class="btn01">Order Now</router-link>
                    <!-- <a href="#" class="btn01" id="orderProduct" data-toggle="modal" data-target="#order">Order Now</a> -->
                </div>
            </div>
            <div class="box" data-aos="fade-up" data-aos-delay="150" data-aos-duration="1500">
                <div class="imgBx">
                    <img src="../assets/product04.jpg">
                </div>
                <div class="text">
                    <h4>Non-woven Fabric</h4>
                    <p>Available in blue, red, green, white, yellow, pink and black.</p>
                    <router-link to="/Shop" class="btn01">Order Now</router-link>
                    <!-- <a href="#" class="btn01" id="orderProduct" data-toggle="modal" data-target="#order">Order Now</a> -->
                </div>
            </div>
            <div class="box" data-aos="fade-up" data-aos-delay="150" data-aos-duration="1500">
                <div class="imgBx">
                    <img src="../assets/rolls02.jpg">
                </div>
                <div class="text">
                    <h4>Non-woven Fabric</h4>
                    <p>Available in blue, red, green, white, yellow, pink and black.</p>
                    <router-link to="/Shop" class="btn01">Order Now</router-link>
                    <!-- <a href="#" class="btn01" id="orderProduct" data-toggle="modal" data-target="#order">Order Now</a> -->
                </div>
            </div>
            <div class="box" data-aos="fade-up" data-aos-delay="150" data-aos-duration="1500">
                <div class="imgBx">
                    <img src="../assets/product05.jpg">
                </div>
                <div class="text">
                    <h4>Non-woven Fabric</h4>
                    <p>Available in blue, red, green, white, yellow, pink and black.</p>
                    <router-link to="/Shop" class="btn01">Order Now</router-link>
                    <!-- <a href="#" class="btn01" id="orderProduct" data-toggle="modal" data-target="#order">Order Now</a> -->
                </div>
            </div>
            <div class="box" data-aos="fade-up" data-aos-delay="150" data-aos-duration="1500">
                <div class="imgBx">
                    <img src="../assets/product07.jpg">
                </div>
                <div class="text">
                    <h4>Non-woven Fabric</h4>
                    <p>Available in blue, red, green, white, yellow, pink and black.</p>
                    <router-link to="/Shop" class="btn01">Order Now</router-link>
                    <!-- <a href="#" class="btn01" id="orderProduct" data-toggle="modal" data-target="#order">Order Now</a> -->
                </div>
            </div>
            
        </div>
    </section>
    <Orderpro />

    <section class="finished-products">
        <div class="title">
            <h2 class="titleText">Finished <span>P</span>roducts</h2>
            <p>This is what our customers manufacture from our products.</p>
            <!-- <p style="font-style: italic;">Shopping bags, Stitched bags, Seedling bags, Suit holders, W-cut and D-cut bags, Non-woven nets</p> -->
        </div>
        <br>
        <div class="slides">
        <div id="fin01">    
        <Finished />
        </div>
        <div id="fin02">
        <Finished01 />
        </div>
        </div>
    </section>

    <section class="partners" id="partners">
        <div class="title">
            <h2 class="titleText">Our <span>P</span>artners</h2>
            <p>We conform 100% to:</p>
        </div>
         <div class="content">
             <div class="box" data-aos="zoom-in" data-aos-delay="200" data-aos-duration="3000">
                 <div class="imgBx">
                     <img src="../assets/kam.jpg">
                 </div>
             </div>
             <div class="box" data-aos="zoom-in" data-aos-delay="200" data-aos-duration="3000">
                <div class="imgBx">
                    <img src="../assets/nema.jpg">
                </div>
            </div>
             <div class="box" data-aos="zoom-in" data-aos-delay="200" data-aos-duration="3000">
                 <div class="imgBx">
                     <img src="../assets/kebs.jpg">
                 </div>
             </div>
         </div>
    </section>


    <section class="testimonials" id="testimonials">
        <div class="title white">
            <h2 class="titleText">What <span>C</span>ustomers Say About Us</h2>
            <p>-Sample customer feedback-</p>
        </div>
        <div class="content">
            <div class="box" data-aos="flip-left" data-aos-delay="200" data-aos-duration="1500">
                <div class="text">
                   <p><strong>"</strong> Ever since we made Ecoeri-green our supplier, we have been able to make durable and quality face masks for our customers. We love and enjoy their fabrics.<strong>"</strong></p> 
                   <h3>EAKO</h3>
                </div>
            </div>
            <div class="box" data-aos="flip-left" data-aos-delay="250" data-aos-duration="1500">
                <div class="text">
                   <p><strong>"</strong> We have experienced great sales since incorporating Eco-eri green's fabrics. That is because they have provided good and quality fabrics to our target customers.<strong>"</strong></p> 
                   <h3>PROMO KINGS</h3>
                </div>
            </div>
            <div class="box" data-aos="flip-left" data-aos-delay="300" data-aos-duration="1500">
                <div class="text">
                   <p><strong>"</strong> We chose Ecoeri-green simply because they offered all the products such as variety of colors and textures we needed to do what we do effectively. We would definitely recommend.<strong>"</strong></p> 
                   <h3>CROWN HEALTH</h3>
                </div>
            </div>
        </div>
    </section>     

    <section class="contact" id="contact">
        <div class="title">
            <h2 class="titleText"><span>C</span>ontact Us</h2>
            <p>We would love to hear from you.</p>
        </div>
        <div class="container">
            <div class="contactForm left" data-aos="fade-right"  data-aos-delay="200" data-aos-duration="1500">
            <h3>Send Message</h3>
            <form id="sendForm">
            <div class="inputBox">
                <input type="text" placeholder="Name" v-model="message.name" required>
                <p id="error-message">Required</p>
            </div>
            <div class="inputBox">
                <input type="text" placeholder="Email" v-model="message.email" required>
                <p id="error-message">Required</p>
            </div>
            <div class="inputBox">
                <textarea placeholder="Text area" v-model="message.text" required></textarea>
                <p id="error-message">Required</p>
            </div>
            <div class="inputBox">
                <button type="button" @click="send" class="logOut">SEND</button>
            </div>
            </form>
        </div>

        <div class="right" data-aos="fade-left"  data-aos-delay="200" data-aos-duration="1500">
            <div class="content">
              <h1>Get in Touch</h1>
              <p class="company">Ecoeri Green Company Limited</p>
                <p class="map"><i class="fas fa-map-marker-alt"></i> Kings Business Park, next to Inland Container Depot, Mombasa Road</p><br>
                    <p><i class="fa fa-phone"></i><strong> Phone:</strong><a href="tel:0707389974" class="phone"> +254 707 38 9974</a></p><br>
                    <p><i class="fa fa-envelope"></i><strong> Email:</strong><a href="mailto:info@eceoerigreen.co.ke" class="email"> info@eceoerigreen.co.ke</a><br></p>
                     <div class="social-media">
                        <a href="https://www.facebook.com/ecoerigreen" class="ico1"><i class="fab fa-facebook"></i></a>
                        <a href="https://wa.me/0707389974" class="ico1"><i class="fab fa-whatsapp"></i></a>
                        <a href="#" class="ico1"><i class="fab fa-twitter"></i></a>
                        <a href="#" class="ico1"><i class="fab fa-linkedin"></i></a>
                        <a href="http://maps.google.com/?q=Kings Business Park, MV6G+FQ4, Nairobi" class="ico1"><i class="fas fa-map-marker-alt"></i></a>
                    </div> 
                    
            </div>

        </div>
        </div> 
    </section>  
    
    <div class="copyrightText">
       <p>&copy; {{ new Date().getFullYear() }} — Copyright ECO-ERI GREEN COMPANY LIMITED . All Rights Reserved.
            </p>
            <p>Designed by <strong><span><a href="http://fineteklabs.com/">FinetekLabs</a></span></strong></p>
    </div>


  </div>
</template>
 

<script>
import Login from '@/components/Login.vue';
import Orderpro from '@/components/Orderpro.vue';
import Finished from '@/components/Finished.vue';
import Finished01 from '@/components/Finished01.vue';
import firebase from '../firebase.js';
import { db,auth } from '../firebase.js'
import Swal from 'sweetalert2';
  export default {
    name: 'Home',

    components: {
         Orderpro, Login, Finished, Finished01
    },
    data(){
      return{
        user: null,  
        email: null,
        showMenu: false,    
        showAccount: false,
        messages:'',
        message: {
        name: null,   
        email: null,
        text: null
        },
        }
  },
    methods:{
        LogOut:function(){
            auth.signOut()
            this.$router.go()
        },

        send(){
            if(this.message.name == null){
                document.querySelector("#error-message").style.display = 'initial'
                return
            }
            if(this.message.email == null){
                document.querySelector("#error-message").style.display = 'initial'
                return
            }
            if(this.message.text == null){
                document.querySelector("#error-message").style.display = 'initial'
                return
            }
            document.querySelector("#error-message").style.display = 'none';
            
            db.collection("messages").add(this.message)
            .then((docRef) => {
                Swal.fire({
                            position: 'center',
                            icon: 'success',
                            title: 'Thank you, We will respond as soon as possible.',
                            showConfirmButton: false,
                            timer: 1900
                            })
                console.log("Document written with ID: ", docRef.id);
                document.querySelector("#sendForm").reset()
            })
            .catch(function(error) {
                console.log("Error adding document: ", error);
            });
            }, 
        },
        created(){
            var user = firebase.auth().currentUser;
            db.collection('profiles').where("email",'==',user.email).get().then(guy=>{
            this.email = guy.docs[0].data().name
            })
            
            firebase.auth().onAuthStateChanged(user => {
                this.user = user;
            });
        },


    
  }
</script>
<style>
#error-message{
  color: red;
  font-size: 10px;
  display: none;
}
.navigation #login-btn{
    font-size: 17px;
    color: white;
    cursor: pointer;
}
.navigation #login-btn:hover{
    color: #9ff21e;
}
.navigation #cart{
      font-size: 17px;
    color: white;
    cursor: pointer;
}
.navigation #cart:hover{
    color: #9ff21e;
}
.signIn{
    cursor: pointer;
    width: 140px;
    font-size: 1em;
    color: #fff !important;
    background: #9ff21e;
    display: inline-block;
    padding: 10px 10px;
    margin-top: 20px;
    text-transform: uppercase;
    text-decoration: none;
    letter-spacing: 2px;
    transition: 0.5s;
    border-radius: 10px;
    box-shadow: 0 5px 35px rgba(0,0,0,0.08);
}
.signIn:hover{
    color: #111 !important;
    text-decoration: none;
}
.logOut{
    cursor: pointer;
    font-size: 1em;
    color: #fff !important;
    width: 140px;
    background: #9ff21e;
    display: inline-block;
    padding: 10px 20px;
    margin-top: 20px;
    text-transform: uppercase;
    text-decoration: none;
    letter-spacing: 2px;
    transition: 0.5s;
    border-radius: 10px;
    box-shadow: 0 5px 35px rgba(0,0,0,0.08);
}
.logOut:hover{
    color: #111 !important;
    text-decoration: none;
}
#menu{
    font-size: 0px;
}
.menu{
    position: absolute;
    top: 108%;
    right: 55px;
    width: 350px;
    padding: 2rem;
    border-radius: .5rem;
    background: #fff;
}
.menu .box{
    display: flex;
    flex-direction: column;
    align-items: left;
    position: relative;
    margin: 1 rem 0;
    transition: ease-in 3s;
}
.menu .menu-title{
    display: flex;
    justify-content: space-between;
}
.menu .menu-title #close{
    font-size: 20px;
    cursor: pointer;
}
.menu .box li{
    list-style: none;
    cursor: pointer;
   
}
.menu .box li a{
    text-decoration: none;
    color: #111;
    font-weight: 300;
}
.menu .box li a:hover{
    color: #9ff21e;
}
.slides{
    display: flex;
    justify-content: center;
}
@media (max-width: 991px){
.slides{
    display: flex;
    flex-direction: column;
    justify-content: center;
}
#menu{
    font-size: 30px;
    position: relative;
    cursor: pointer;
    color: #fff;
    text-decoration: none;
}
.slides #fin02{
    display: none;
}
}
</style>
