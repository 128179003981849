import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import vuetify from './plugins/vuetify'
import VueFirestore from 'vue-firestore'
import firebase from './firebase.js'
import { BootstrapVue, IconsPlugin } from 'bootstrap-vue'
// import VueSweetalert2 from 'vue-sweetalert2';

import AOS from "aos";

import 'bootstrap/dist/css/bootstrap.css';
import 'bootstrap-vue/dist/bootstrap-vue.css';
import 'sweetalert2/dist/sweetalert2.min.css';
import "aos/dist/aos.css";

//Install BootstrapVue
Vue.use(BootstrapVue);
Vue.use(IconsPlugin);
//Vue.use(VueSweetalert2);
Vue.config.productionTip = false;
Vue.use(VueFirestore);

import Chartkick from 'vue-chartkick'
// import Chart from 'chart.js'
import Chart from 'chart.js/auto';


Vue.use(Chartkick.use(Chart));

import Swal from 'sweetalert2';
window.Swal = Swal;

let app = '';
firebase.auth().onAuthStateChanged(function() {
  if(!app){
    new Vue({
      created() {
        AOS.init();
      },
      router,
      store,
      vuetify,
      render: h => h(App)
    }).$mount('#app');
  }
  });

