<template>
    <div class="account">
        <b-navbar toggleable="lg" type="dark" id="shop_logo">
            <b-navbar-brand @click="goHome" href="#">
               <div class="logo"> ECOERI<span>.</span></div>
            </b-navbar-brand>

        </b-navbar>
        <div class="details">
            <div class="recentOrders">
                <div class="cardHeader">
                    <h2>Account Overview</h2>
                    <p>{{ email }}</p>
                    <hr>
                </div>
                    <div>
                        <b-card title="Account Details" v-for="user in users" :key="user">
                            <b-card-text>
                             {{user.name}}
                            </b-card-text>

                            <b-card-text>
                             {{user.email}}
                            </b-card-text>

                            <b-card-text>
                              {{user.phone}}  
                            </b-card-text>

                            
                            <!-- Button trigger modal -->
                            <a href="#" class="btnPass" data-toggle="modal" data-target="#exampleModalCenter">
                            Change password
                            </a>

                            <!-- Modal -->
                            <div class="modal fade" id="exampleModalCenter" tabindex="-1" role="dialog" aria-labelledby="exampleModalCenterTitle" aria-hidden="true">
                            <div class="modal-dialog modal-dialog-centered" role="document">
                                <div class="modal-content">
                                <div class="modal-header">
                                    <h5 class="modal-title" id="exampleModalLongTitle">Password Reset</h5>
                                    <button type="button" class="close" data-dismiss="modal" aria-label="Close">
                                    <span aria-hidden="true">&times;</span>
                                    </button>
                                </div>
                                <div class="modal-body">
                                    <div class="form-group">
                                    <input type="text" placeholder="Current password" class="form-control">
                                    </div>

                                    <div class="form-group">
                                    <input type="text" placeholder="New password" class="form-control">
                                    </div>
                                </div>
                                <div class="modal-footer">
                                    <button type="button" class="logOut">Save</button>
                                </div>
                                </div>
                            </div>
                            </div>
                        </b-card>
                    </div>

                </div>
            </div>

            

    </div>
</template>
<script>
import firebase from '../firebase.js';
import { db } from '../firebase.js'
export default {
    data() {
        return{
            name: null,  
            email: null,
            users: '',
            user: {   
            name: null,
            email: null,
            phone: null
            },
        }
    },
    methods: {
        goHome(){
           this.$router.push('/') 
        },

        created(){
            var user = firebase.auth().currentUser;
            db.collection('profiles').where("email",'==',user.email).get().then(guy=>{
            this.email = guy.docs[0].data().name
            // console.log(data)
            })
            
        },
    }
}
</script>
<style>
.btnPass{
    text-decoration: none;
    position: relative;
    padding: 5px 10px;
    background: #00c18a;
    text-decoration: none;
    color: #fff;
    border-radius: 6px;
}
.btnPass:hover{
    box-shadow: 0 7px 25px rgba(0,0,0,0.08);
    text-decoration: none;
    color: #fff;
}
</style>