import Vue from 'vue'
import VueRouter from 'vue-router'
import Home from '../views/Home.vue'
import Shop from '../views/Shop.vue'
import ShoppingCart from '../views/ShoppingCart.vue'
import SignIn from '../views/SignIn.vue'
import Cart from '../views/Cart.vue'
import AccountDetails from '../views/AccountDetails.vue'
import Admin from '../views/Admin.vue'
import Overview from '../views/Overview.vue'
import Orders from '../views/Orders.vue'
import OrderDetails from '../views/OrderDetails.vue'
import ProductsList from '../views/ProductsList.vue'
import Messages from '../views/Messages.vue'
import Users from '../views/Users.vue'
import firebase from '../firebase.js'


Vue.use(VueRouter)

const routes = [
  {
    path: '/',
    name: 'Home',
    component: Home
  },
  {
    path: '/Shop',
    name: 'Shop',
    component: Shop
  },
  {
    path: '/SignIn',
    name: 'SignIn',
    component: SignIn
  },
  {
    path: '/ShoppingCart',
    name: 'ShoppingCart',
    component: ShoppingCart
  },
  {
    path: '/Cart',
    name: 'Cart',
    component: Cart
  },
  {
    path: '/AccountDetails',
    name: 'AccountDetails',
    component: AccountDetails
  },
  {
    path: '/Admin',
    name: 'Admin',
    component: Admin,
    meta: { requiresAuth: true},
    children:[
      {
        path: 'Overview',
        name: 'Overview',
        component: Overview
      },
      {
        path: 'Orders',
        name: 'Orders',
        component: Orders
      },
      {
        path: 'OrderDetails',
        name: 'OrderDetails',
        component: OrderDetails
      },
      {
        path: 'ProductsList',
        name: 'ProductsList',
        component: ProductsList
      },
      {
        path: 'Messages',
        name: 'Messages',
        component: Messages
      },
      {
        path: 'Users',
        name: 'Users',
        component: Users
      }
    ]
  },

]

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes
});

router.beforeEach((to, from, next) => {

  const requiresAuth = to.matched.some(x => x.meta.requiresAuth)
  const currentUser = firebase.auth().currentUser
  

  if (requiresAuth && !currentUser) {
      next('/')
  } else if (requiresAuth && currentUser) {
      next()
  } else {
      next()
  }
})

export default router;
