<template>
  <div class="orderProduct">
<!-- Modal -->
<div class="modal fade" id="order" tabindex="-1" aria-labelledby="OrderTitle" aria-hidden="true">
  <div class="modal-dialog modal-dialog-centered">
    <div class="modal-content">
      <div class="modal-header">
        <h5 class="modal-title" id="exampleModalLabel">Request Quote</h5>
        <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
      </div>
      <div class="modal-body">
         <div id="images">
            <img src="../assets/rolls03.jpg">
            </div> 

        <form id="details">
         <p>Hi {{email}}</p>
         <input class="form-control" type="text" placeholder="Input phone number" v-model="product.address" required>
         <p id="error-message">Phone number is required</p>
  <br>      
          <select class="d-block pa-3 rounded-lg" v-model="product.color" style="border: solid #808080 1px; width: 225px; height: 40px;" >
              <option value="Select color" required>Select color</option>
              <option v-for="item in items" :value="item" :key="item">{{item}}</option>
          </select>
          <p id="error-message">Fabric color is required</p>
<br>
          <!-- <select class="d-block pa-3" v-model="product.quantity" @change="PriceCalc()" style="border: solid #808080 1px; width: 225px; height: 40px;">
              <option value="Select quantity">Select quantity</option>
              <option v-for="n in 10" :key="n" :value="n">{{n}}</option>
          </select>
<br> -->
         <input class="form-control" type="text" placeholder="Input quantity/kg" v-model="product.quantity" @input="PriceCalc()" required>
          <p id="error-message">Quantity is required</p>
<br>         
          <input type="text" placeholder="Price"  v-model="product.price" class="form-control" disabled readonly>

        </form>
      </div>
      <div class="modal-footer">
        <a href="#" type="submit" class="btn01" @click="submit()">Order</a>
      </div>
    </div>
  </div>
</div>
  </div>
</template>

<script>
import firebase from '../firebase.js';
import { db,auth } from '../firebase.js'
export default {
    data(){
      return{
          name: null,  
          email: null,
          product: {
          address: null,
          color: 'Select color',
          quantity: null,
          price: null,
          vat: null,
          total: null,
          isDelivered: false,
          isCancelled: false
          },
          items: ['Red', 'Blue', 'Pink', 'Yellow', 'White', 'Black'],
      }
   },
  
  methods: {
    submit(){
      if(this.product.address == null){
        document.querySelector("#error-message").style.display = 'initial'
        return
      }
       if(this.product.color == null){
        document.querySelector("#error-message").style.display = 'initial'
        return
      }
       if(this.product.quantity == null){
        document.querySelector("#error-message").style.display = 'initial'
        return
      }

      document.querySelector("#error-message").style.display = 'none'
      
      auth.onAuthStateChanged(user=>{
        if(user){
          //this.prod['user']=user.email
          db.collection("order-products").add(this.product)
          .then((docRef) => {
             // eslint-disable-next-line no-undef
             //$('#order').modal('hide')        
             alert('Your order has been received');
             console.log("Document written with ID: ", docRef.id);   
             document.querySelector("#details").reset()        
          })
          .catch(function(error) {
            console.log("Error adding document: ", error);
          });
        }
        else{
          
         this.$router.push({name:'Submit',params:{}});
        }
      })
    },

    PriceCalc:function(){
      var a = parseInt(this.product.quantity)
      if(a){
        this.product.price =  500*a
      }
      else[
        this.product.price = null
      ]
    }
  },
    created(){
      var user = firebase.auth().currentUser;
      db.collection('profiles').where("email",'==',user.email).get().then(guy=>{
        this.email = guy.docs[0].data().name

      })
      //this.name = user.displayName;
    }

}
</script>

<style scoped>
#images img{
    width: 200px;
    height: 200px;
}
.modal-body{
    display: flex;
    justify-content: space-around;
}
#error-message{
  color: red;
  font-size: 10px;
  display: none;
}
@media (max-width: 480px){
  #images img{
    display: none;
}
.modal-body{
    display: flex;
    justify-content: center;
}
}
</style>