<template>
  <div class="home">
    <Navbar /> 
        <!-- <div>
        <b-navbar toggleable="lg" type="dark" id="shop_logo">
            <b-navbar-brand @click="goHome" href="#">
               <div class="logo"> ECOERI<span>.</span></div>
            </b-navbar-brand>

            <b-navbar-toggle target="nav-collapse"></b-navbar-toggle>

            <b-collapse id="nav-collapse" is-nav>
               <b-navbar-nav class="ml-auto">
                <b-nav-item active>
                    <p style="color: #fff; font-size: 17px;">Hi, {{email}}</p>
                </b-nav-item>   
                <b-nav-item @click="gotoCart" active>
                    <i class="fa fa-shopping-cart" aria-hidden="true"> ({{cart.length}}) </i>
                </b-nav-item>
                <b-nav-item active data-toggle="modal" data-target="#login-modal">
                   <i class="fas fa-user" id="login-btn"></i>
                </b-nav-item>
              </b-navbar-nav> 
            </b-collapse>
        </b-navbar>
        <Login />

    </div> -->
    <Products />
    <!-- @add-to-cart="updateCart" -->
  </div>
</template>

<script>
// @ is an alias to /src
import Navbar from '@/components/Navbar.vue'
// import Login from '@/components/Login.vue'
import firebase from '../firebase.js';
import { db,auth } from '../firebase.js'
import Products from '@/components/Products.vue'
export default {
  name: 'Home',
  components: { 
    Products, Navbar
  },
    data() {
      return {
          shoppingCart: 0,
          name: null,  
          email: null,
      }
    },

    created(){
        if (JSON.parse(localStorage.getItem('products'))) {
            this.cart = JSON.parse(localStorage.getItem('products'))
        }
        else {
            return
        }
         var user = firebase.auth().currentUser;
         db.collection('profiles').where("email",'==',user.email).get().then(guy=>{
            this.email = guy.docs[0].data().name

        })
    },

    methods: {
        // updateCart(){
        //   this.shoppingCart +=1
        // },
        gotoCart(){
            this.$router.push('/Cart')
        },
        goHome(){
           this.$router.push('/') 
        },
        onSubmit(e){
            e.preventDefault();
            if (this.form.user=='admin' && this.form.pass=='456789') {
                this.$refs['my-modal'].hide()
                this.$router.push('/admin')
                localStorage.setItem('admin', JSON.stringify('true'))
            }
            else {
                return false;
            }
        },
        LogOut:function(){
            auth.signOut()
        },
        
    }
}
</script>
<style>
#shop_logo{
    background: #007481;
}
.logo{
   color: #fff;
   font-weight: 700;
   font-size: 1.4em;
}
.logo span{
    color: #9ff21e;
}
.shop_header{
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 70px;
    padding: 10px 100px;
    z-index: 10000;
    background: #007481;
    align-items: center;
    transition: 0.5s;
}
.shop_header #icons{
    display: flex;
    
}
.shop_header #login-btn{
    font-size: 17px;
    color: white;
    cursor: pointer;
    margin-right: 20px;
}
.shop_header #login-btn:hover{
    color: #9ff21e;
} 
.shop_header #cart{
    font-size: 17px;
    color: white;
    cursor: pointer;
}
.shop_header .cart{
    font-size: 17px;
    color: white;
    cursor: pointer;
}
.shop_header #cart:hover{
    color: #9ff21e;
} 
.shop_header #cart .sticky{
    color: #111;
}
.shop_header .cart .sticky{
    color: #111;
}
</style>
