<template>
  <div class="overview">
      <div class="mainUsers">
                <!-- <Topbar /> -->
              <br>
              <div>
                 <h3>Orders Page</h3>
                 <p>
                   Orders received after checkout, including the email of the respective user.
                 </p>
     
      </div>
      <br>
 
       <div>
        <b-container class="mt-4" fluid>
            <b-table :busy="busy" bordered :fields="fields" hover :items="items">
                <template v-slot:cell(email)="data">
                    <h4>{{data.item.email}}</h4>
                </template>

                <template v-slot:cell(products)="data">
                    <h5>Id: {{data.item.id}}</h5>
                    <!-- <h5>User: {{data.item.email}}</h5> -->
                    <!-- <h5>User: {{product.email}}</h5> -->
                    <ul v-for="(product,index) in data.item.products" :key="index">
                        <li>Name: {{product.name}}</li>
                        <li>Price: {{product.price}}</li>
                        <li>Quantity: {{product.quantity}}</li>
                        <hr>
                    </ul>
                </template>

                <template v-slot:cell(total)="data">
                    <h2>Kshs: {{data.item.total}}.00</h2>
                </template>

                <template v-slot:cell(modify)="data">
                    <b-button @click="deletesale(data.item.id)" block class="mt-4"
                    style="top:50%!important;" size="lg" variant="danger"
                    >
                        Delete
                    </b-button>
                </template>

                <template v-slot:table-busy>
                   <div class="text-center text-success my-2">
                       <b-spinner class="align-middle"></b-spinner>
                       <strong>Loading...</strong>
                    </div> 
                </template>

            </b-table>
        </b-container>
    </div>
   </div> 
  </div>
</template>

<script>
// import firebase from '../firebase.js';
import { db } from '../firebase.js'
// import Topbar from '@/components/Topbar.vue'
export default {
    name: 'Orders',
    components: {
        // Topbar
    },
    data() {
        return{
            data: "",
            busy: true,
            items: [],
            fields: [
                { key: "email", label: "User"},
                { key: "products", label: "Sales"},
                { key: "total", label: "Total" },
                { key: "modify", label: "Modify" },
            ]
        };
    },
    created() {
        db.collection("cart").onSnapshot(querySnapshot => {
            this.items=[]
            this.busy=true
            querySnapshot.forEach(doc => {
                const doc2 = doc.data();
                console.log(
                    doc2
                )
                doc2.id = doc.id;
                this.items.push(doc2);
            })
            if (this.items.length) {
                this.busy = false
            }
            else {
                this.busy = false
            }
        })
        // var user = firebase.auth().currentUser;
        // this.email = user.email;
        },
        
    methods: {
        deletesale:function(id){
          db.collection("cart").doc(id).delete().then(()=>{
          delete this.items
       })
    }
    },

   
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style>

</style>