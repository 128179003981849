<template>
     <div class="orderDetails">
         <div class="mainUsers">
                <!-- <Topbar /> -->
              <br>
              <div>
                 <h3>OrderDetails Page</h3>
                 <p>
                   Orders received after checkout, including the email of the respective user.
                 </p>
             
     
      </div>
      <br>

    <div class="details01">
       <div class="recentOrders">
                    <div class="cardHeader">
                        <h2>Order Details</h2>
                    </div>
                    <table>
                        <thead>
                            <tr>
                                <td>OrderId</td>
                                <td>User Email</td>
                                <td>Total</td>
                                <td>Payment</td>
                                <td>Status</td>
                            </tr>
                        </thead>
                        <tbody>
                            <tr v-for="detail in details" :key="detail"> 
                                <td>{{ detail.id }}</td>
                                <td>{{ detail.email }}</td>
                                <td>{{ detail.total }}</td>
                                <td>
                                  <div class="form-group">
                                    <!-- <label for="exampleFormControlSelect1">Example select</label> -->
                                    <select class="form-control" id="exampleFormControlSelect1">
                                      <option>Paid</option>
                                      <option>Due</option>
                                    </select>
                                  </div>
                                </td>
                                <td>
                                  <div class="form-group">
                                    <b-form-select v-model="detail.status" :options="options"></b-form-select>
                                  </div>
                                </td>
                                <!-- <td><button class="btn btn-primary" data-toggle="modal" data-target="#orderEdit" @click="editOrder(order)">Edit</button></td> -->
                                <!-- <td><button @click="saveData" class="btn btn-success">Save</button></td> -->
                            </tr>
                        </tbody>
                    </table>
                    <span style='display:none'>{{Created()}}</span>
                    <span style='display:none'>{{See()}}</span>
                </div>

        <div class="modal fade" id="orderEdit" tabindex="-1" role="dialog" aria-labelledby="orderTitle" aria-hidden="true">
          <div class="modal-dialog modal-dialog-centered" role="document">
            <div class="modal-content">
              <div class="modal-header">
                <h5 class="modal-title" id="exampleModalLongTitle">Edit Order</h5>
                <button type="button" class="close" data-dismiss="modal" aria-label="Close">
                  <span aria-hidden="true">&times;</span>
                </button>
              </div>
              <div class="modal-body">
                <div class="form-group">
                  <input type="text" placeholder="Id" v-model="detail.id" class="form-control">
                </div>
                <div class="form-group">
                  <input type="text" placeholder="Email" v-model="detail.email" class="form-control">
                </div>
                 <div class="form-group">
                  <input type="text" placeholder="Total" v-model="detail.total" class="form-control">
                </div>
                <!-- <p>Payment</p> -->
                <div class="form-group">
                  <!-- <b-form-radio-group v-model="detail.payment" :radios="radios" name="radio-inline"></b-form-radio-group> -->
                  <input type="text" placeholder="Payment" v-model="detail.payment" class="form-control">
                </div>
                <div class="form-group">
                  <b-form-select v-model="detail.status" :options="options"></b-form-select>
                </div>
              </div>
              <div class="modal-footer">
                <button type="button" class="btn btn-secondary" data-dismiss="modal">Close</button>
                <button type="button" class="btn btn-primary" @click="updateOrder()">Save changes</button>
              </div>
            </div>
          </div>
        </div>        


    </div>            
     </div>
     </div>
</template>
<script>
// import Topbar from '@/components/Topbar.vue'
import { db } from '../firebase.js'
export default {
  name: "OrderDetails",
    components: {
        // Topbar
    },
    props: {
      msg: String
    },
    data() {
        return{
            details:'',
            detail: {
            orderId: null,   
            email: null,
            price: null,
            payment: null,
            status: null,
            },
            activeItem: null,
            options: [
              { text: 'Delivered' },
              { text: 'Pending' },
              { text: 'In Progress' },
              { text: 'Return' }
          ],
        }
    },
    methods:{
      saveData(){
        db.collection("orderDetails").add(this.detail)
        .then((docRef) => {
          console.log("Document written with ID: ", docRef.id);
          // this.reset()  
          //this.readData();
        })
        .catch(function(error) {
          console.log("Error adding document: ", error);
        });
      },

    Created: async function(){
        if(Object.keys(this.details).length>0){return}
        var deets = {}
        var d = ( await db.collection("cart").get()).docs
        for(var i=0;i<d.length;i++){
          var doc = d[i]
        deets[doc.id] = doc.data()
        deets[doc.id]['id'] = doc.id
        }
        this.details = deets
        this.See()
    },
    See: function(){
    console.log(this.details)
    },

    updateOrder(){
      db.collection("orderDetails").doc(this.activeItem).update(this.detail)
      .then(function() {
        console.log("Document successfully updated!");
      })
      .catch(function (error) {
        console.error("Error updating document: ", error);
      })
    },

    
    editOrder(detail){
      this.detail = detail;
      this.activeItem = detail.id;
    },
    },
    
    
}
</script>
<style>
.details01{
    position: relative;
    width: 100%;
    padding: 20px;
    display: grid;
}
.details01 .recentOrders{
    position: relative;
    display: grid;
    height: 1000px;
    background: #fff;
    padding: 20px;
    box-shadow: 0 7px 25px rgba(0,0,0,0.08);
    border-radius: 20px;
}
</style>