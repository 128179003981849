<template>
<div class="sub">
        <b-navbar toggleable="lg" type="dark" id="shop_logo">
            <b-navbar-brand @click="goHome" href="#">
               <div class="logo"> ECOERI<span>.</span></div>
            </b-navbar-brand>

        </b-navbar>
        <!-- <Navbar /> -->
  <div class="submit">
    <div class="submit_body"> 

        <div class="account_form">

            <ul class="nav nav-fill nav-pills mb-3" id="pills-tab" role="tablist">
                <li class="nav-item">
                    <a class="nav-link active" id="pills-home-tab" data-toggle="pill" href="#pills-login" role="tab" aria-controls="pills-login" aria-selected="true">Login</a>
                </li>
                <li class="nav-item">
                    <a class="nav-link" id="pills-register-tab" data-toggle="pill" href="#pills-register" role="tab" aria-controls="pills-register" aria-selected="false">Signup</a>
                </li>
            </ul>

            <div class="tab-content" id="pills-tabContent">
            <div class="tab-pane fade show active" id="pills-login" role="tabpanel" aria-labelledby="pills-login-tab">

                <h5 class="text-center">Login Please</h5>

                <form id="login-form">
                <div class="form-group">
                    <label for="exampleInputEmail1">Email address</label>
                    <input type="email" v-model="email" class="form-control" id="exampleInputEmail1" aria-describedby="emailHelp" placeholder="Enter email" required>
                    <small class="form-text text-muted">Required</small>
                </div>
                <div class="form-group">
                    <label for="exampleInputPassword1">Password</label>
                    <input type="password" @keyup.enter="login" v-model="password" class="form-control" id="exampleInputPassword1" placeholder="Password" required>
                </div>

                <div class="form-buttons">
                    <div class="form-group">
                    <button class="btn btn-primary" type="button" @click="login">Login</button>
                </div>
                <!-- <div class="form-group">
                    <button class="btn btn-primary" @click="LogOut()">Logout</button>
                </div> -->
                </div>
                </form>

            </div>
            <div class="tab-pane fade" id="pills-register" role="tabpanel" aria-labelledby="pills-register-tab">

         <h5 class="text-center">Create New Account</h5>
            <form id="signup-form">    
                <div class="form-group">
                    <label for="name">Full name</label>
                    <input type="text" v-model="name" class="form-control" id="name" placeholder="Full name" required>
                </div>

                <div class="form-group">
                    <label for="name">Phone number</label>
                    <input type="text" v-model="phone" class="form-control" id="phone" placeholder="Phone number" required>
                </div>

                <div class="form-group">
                    <label for="email">Email address</label>
                    <input type="email"  v-model="email" class="form-control" id="email" aria-describedby="emailHelp" placeholder="Enter email" required>
                </div>
                <div class="form-group">
                    <label for="password">Password</label>
                    <input type="password" v-model="password" class="form-control" id="password" placeholder="Password" required>
                </div>

                <div class="form-group">
                    <button class="btn btn-primary" @click="register" type="button">Signup</button>
                </div>
            </form>
            </div>
            </div>


        </div>

    </div>
  </div>
</div>  
</template>

<script>
// import Navbar from '@/components/Navbar.vue'
import firebase from '../firebase.js'
import { db } from '../firebase.js'
import Swal from 'sweetalert2';
export default{
  name: "SignIn",
//   components: {
//     Navbar
//   },

  data(){
      return {
          name: null,
          email: null,
          password: null,
          userType: null,
          user:''
      }
  },

  methods:{
        register(){
            firebase.auth().createUserWithEmailAndPassword(this.email, this.password)
               .then((user) => {
                   this.user = user
                //    eslint-disable-next-line no-undef
                //    $('#login').modal('hide');
                 db.collection("profiles").add({
                    name: this.name,
                    email: this.email,
                    phone: this.phone
                   })      
                              

                    .then(function() {
                        console.log("Document successfully written!");
                        // alert('Sign up successful')
                       const Toast = Swal.mixin({
                        toast: true,
                        position: 'top-end',
                        showConfirmButton: false,
                        timer: 3000,
                        timerProgressBar: true,
                        didOpen: (toast) => {
                            toast.addEventListener('mouseenter', Swal.stopTimer)
                            toast.addEventListener('mouseleave', Swal.resumeTimer)
                        }
                        })

                        Toast.fire({
                        icon: 'success',
                        title: 'Sign up was successful'
                        })

                        document.querySelector("#signup-form").reset();
                        this.$router.replace('/');
                    })
                    .catch(function(error) {
                        console.error("Error writing document: ", error);
                    });

                    //this.$router.replace('Admin');
                })
                .catch(function(error) {
                // Handle Errors here.
                var errorCode = error.code;
                var errorMessage = error.message;
                if (errorCode == 'auth/weak-password') {
                    alert('The password is too weak.');
                } else {
                    alert(errorMessage);
                }
                console.log(error);
            });
                // Add a new document with a generated id.
            db.collection("profiles").add({
                    name: this.name,
                    email: this.email,
                    phone: this.phone
            })
            .then((docRef) => {
                console.log("Document written with ID: ", docRef.id);
            })
            .catch((error) => {
                console.error("Error adding document: ", error);
            });
      },

        login(){
          firebase.auth().signInWithEmailAndPassword(this.email, this.password)
                .then(() => {
                        console.log("Document successfully written!");
                        const Toast = Swal.mixin({
                            toast: true,
                            position: 'top-end',
                            showConfirmButton: false,
                            timer: 3000,
                            timerProgressBar: true,
                            didOpen: (toast) => {
                                toast.addEventListener('mouseenter', Swal.stopTimer)
                                toast.addEventListener('mouseleave', Swal.resumeTimer)
                            }
                            })

                            Toast.fire({
                            icon: 'success',
                            title: 'Signed in successfully'
                        })

                        document.querySelector("#login-form").reset();
                        this.$router.replace('/');

                        
                        
                        var user = firebase.auth().currentUser;
                        db.collection('profiles').where("email",'==',user.email).get().then(person=>{
                            this.email = person.docs[0].data().userType;

                            if ( this.email === 'Admin' ) {
                                this.$router.replace('Admin'); 
                            }
                            else {
                                this.$router.replace('/');
                            }

                        })
                        
                        
                })
                .catch(function(error) {
                    // Handle Errors here.
                    var errorCode = error.code;
                    var errorMessage = error.message;
                    if (errorCode === 'auth/wrong-password') {
                        alert('Wrong password.');
                    } else {
                        alert(errorMessage);
                    }
                    console.log(error);
            });

      },  
    //   LogOut:function(){
    //         auth.signOut()
    //   },
      goHome(){
           this.$router.push('/') 
      },

  } 
};

</script>

<style>
.submit_body{
  width: 700px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}
.submit{
  display: flex;
  justify-content: center;
  align-items: center;
  height: 85vh;
}
.account_text{
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: center;
  border: 1px #808080 solid;
  font-size: 17px;
  font-weight: bold;
  width: 500px;
  background: #ECEFF1;
}
.account_text p{
    display: flex;
  justify-content: center;
  align-items: center;
  text-align: center;
  padding-top: 12px;
}
.account_form{
  width: 700px;
}
.account_text .bx-info-circle{
  font-size: 25px;
  padding-left: 10px;
}
@media (max-width: 700px){
   .submit_body{
  width: 500px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
} 
.account_form{
  width: 500px;
}
}
@media (max-width: 500px){
   .submit_body{
  width: 400px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
} 
.account_form{
  width: 400px;
}
}
@media (max-width: 400px){
   .submit_body{
  width: 300px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
} 
.account_form{
  width: 300px;
}
}
</style>