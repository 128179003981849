<template>
    <div>
        <Navbar />
        <div class="shopping" style="margin-top: 36px; margin-left: 20px;">
            <h3>Cart</h3>
        </div>
        <div class="cart" v-if="cart.length > 0">
        <b-container fluid class="mt-4">
            <div
            style="display: flex;
            justify-content: space-between;"
            >              
                <div class="ml-auto shoppppp" v-if="cart.length > 0">
                     <b-button @click="clean" style="background: #9ff21e;" class="mr-2" >
                        Clear Cart
                    </b-button>
                 </div>   
            </div>
            <br>
            
            <div class="larger_view">
            <b-row fluid class="tableRows">
                <b-col class="text-center">
                    <b-table bordered hover :items="cart" :fields="fields">
                        <!-- <template v-slot:cell(#)="data">
                            {{ data.index+1 }}
                        </template> -->

                         <template v-slot:cell(price)="data">
                            {{ data.item.price * data.item.quantity }}
                        </template>

                         <template v-slot:cell(remove)="data">
                            <b-button @click="remove(data.item.id)" variant="danger" class="mr-2" size="sm">
                                <b-icon icon="trash-fill" scale="1"></b-icon>
                            </b-button>
                        </template>

                         <template v-slot:cell(quantity)="data">
                            <b-row>
                                <b-col cols="3" class="text-center">
                                   <b-button variant="primary" @click="increment(data.item.id)" class="mr-2" size="sm">
                                        +
                                    </b-button>
                                </b-col>

                                <b-col cols="6" class="text-center">
                                    <h5>{{ data.item.quantity }}</h5>
                                </b-col>
                                
                                <b-col cols="3" class="text-center">
                                    <b-button :disabled="data.item.quantity <=1" variant="primary" size="sm"
                                    @click="decrement(data.item.id)" class="mr-2">
                                        -
                                    </b-button>
                                    
                                </b-col>
                                
                            </b-row>
                        </template>

                        <template v-slot:cell(image)="data">
                            <b-col class="text-center">
                                <b-img style="max-width: 5rem;" :src="require(`@/assets/fabric/roll${data.item.id}.png`)" fluid alt="Responsive image"></b-img>
                            </b-col>
                        </template>
                    </b-table>
                </b-col>
            </b-row>
            </div>

            <div
             class="text-center jigi" 
             style="display: flex;
            justify-content: space-between;"
            >
                <div class="blah">
                    <b-button @click="gotoShop" variant="outline-success" class="mr-2">
                         <b-icon icon="chevron-double-left" scale="1"></b-icon>
                        Continue Shopping
                    </b-button>
                </div>

                <div class="bleh" >
                    <b-button @click="buy" variant="success" class="mr-2" style="width: 203px;">
                        Checkout
                    </b-button>
                </div>
                <hr>
                <div class="blih">
                    <a href="tel:0707389974" class="btn01"> <i class="fa fa-phone"></i>Call to Order</a>
                </div>
            </div>


            <b-modal no-close-on-esc no-close-on-backdrop ref="modal-1" centered >
                <h5>Purchase Completed</h5>
                <p class="mb-4">Products:</p>
                <ul v-for="productFinal in ticket.products" :key="productFinal.id">
                    <!-- <li>
                        User: {{ email }}
                    </li> -->
                    <li>
                        Product name: {{ productFinal.name }}
                    </li>
                    <li>
                        Quantity: {{ productFinal.quantity }} kg
                    </li>
                    <li>
                        Price: Kshs {{ productFinal.price }}
                    </li>
                    <li>
                        Total: Kshs {{ productFinal.price * productFinal.quantity }}
                    </li>
                    <hr>
                </ul>
                <h2 class="my-4 text-center">Total: Ksh{{ticket.total}}.00</h2>
                <template>
                    <b-button class="mt-3" variant="info" block @click="checkout" >
                        Complete order
                    </b-button>
                </template>
            </b-modal>
        </b-container>
    </div>

    <div id="cart_cont" v-else>
        <img src="../assets/shopping_cart.png" style="max-width: 8rem;">
        <h4 style="color: #808080">Your cart is empty!</h4>
        <router-link to="/Shop" class="btn01">START SHOPPING</router-link>
    </div>

   </div> 
</template>
<script>
// import firebase from '../firebase.js';
import { db,auth } from '../firebase.js';
import Navbar from '@/components/Navbar.vue';
import Swal from 'sweetalert2';

export default {
    name: 'Cart',
    components: {
        Navbar
    },
    data() {
        return {
            ticket: {
                // email: null,
                products: null,
                total: 0
            },
            counter: 0,
            cart: [],
            fields: ['image', 'name', 'quantity', 'price', 'remove']
        }    
    },
    methods: {
        gotoShop(){
            this.$router.push('/Shop')
        },
        clean() {
            this.cart=[];
            for (const key in this.cart) {
                this.cart[key].cart=false
                this.cart[key].quantity=1
            }
            localStorage.setItem('products', JSON.stringify(this.cart))
            this.$refs['modal-1'].hide()
            // this.$router.go()
        },
        checkout() {
            db.collection('cart').add(this.ticket)
            .then((docRef) => {      
                // alert('Your order has been received');
                console.log("Document written with ID: ", docRef.id); 
                Swal.fire({
                    position: 'top-end',
                    icon: 'success',
                    title: 'Your order has been received',
                    showConfirmButton: false,
                    timer: 1500
                    })  
                // this.$router.go()    
                        
            })
            .catch(function(error) {
                console.log("Error adding document: ", error);
            });
            
            this.cart=[];
            for (const key in this.cart) {
                this.cart[key].cart=false
                this.cart[key].quantity=1
            }
            localStorage.setItem('products', JSON.stringify(this.cart))
            this.$refs['modal-1'].hide()
            // this.$router.go()
        },
        remove(id) {
            for (let index = 0; index < this.cart.length; index++) {
                if(this.cart[index].id == id) {
                    this.cart.splice(index,1);
                    localStorage.setItem('products', JSON.stringify(this.cart))
                }
            }
        },
        buy() {
             auth.onAuthStateChanged(user=>{
                if(user){
                this.ticket={
                email: auth.currentUser.email,
                products: this.cart,
                total: this.total
                }
                
                this.$refs['modal-1'].show()
                }
                else{
                    Swal.fire({
                        position: 'top-end',
                        icon: 'error',
                        title: 'Oops...',
                        text: 'You must have an acccount to make an order!',
                        showConfirmButton: false,
                        timer: 2500
                    })
                    this.$router.push({name:'SignIn',params:{}});
                }
             })
             
        },
        increment(id) {
            for (let index = 0; index < this.cart.length; index++) {
                if(this.cart[index].id == id) {
                    this.cart[index].quantity++
                    localStorage.setItem('products', JSON.stringify(this.cart))
                }
            }
        },
        decrement(id) {
            for (let index = 0; index < this.cart.length; index++) {
                if(this.cart[index].id == id) {
                    this.cart[index].quantity--
                    localStorage.setItem('products', JSON.stringify(this.cart))
                }
            }
        }
    },

    computed: {
    total(){
            let t=0;
            for (let index=0; index < this.cart.length; index++) {
                t+= this.cart[index].price * this.cart[index].quantity
            }
            return t
        }
    },

    created() {
        if(JSON.parse(localStorage.getItem('products'))) {
            this.cart = JSON.parse(localStorage.getItem('products'))
        }
        else {
            this.cart = []
        }
    }
}
</script>
<style>
.cart{
    margin-top: 50px;
}
.blih{
    display: none;
}
#cart_cont{
    display: flex; 
    justify-content: center;
    align-items: center;
    flex-direction: column;
}
.mobile_view{
    display: none;
}
/* @media (max-width: 967px){
.mobile_view{
    display: initial;
}
.mobile_view .qty{
    display: flex;
    justify-content: center;
}
.larger_view{
    display: none;
}
} */
@media (max-width: 700px){
    .tableRows{
        overflow-x: auto;
    }
    .datsss{
        display: none;
    }
    .jigi{
        display: flex;
        flex-direction: column;
    }
    .blah{
        margin-top: 20px;
        margin-bottom: 20px;
    }
    .blih{
        display: initial;
    }
}

</style>